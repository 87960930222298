@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.lesson-planner-list {
  height: 100%;

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 20px;
      margin-right: 20px;
    }
  }

  &--title {
    display: flex;
    flex-direction: column;

    &--durations {
      display: flex;

      column-gap: 10px;

      span {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        color: colors.get-color(colors.$cool_grey, level_5);
      }

      b {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-number-font;
        color: colors.get-color(colors.$colors, black);
      }
    }

    &--separator {
      height: 20px;
      width: 2px;
      background-color: colors.get-color(colors.$cool_grey, level_6);
    }

    &--pending {
      color: colors.get-color(colors.$colors, black);
      width: 150px;
      border: 1px Solid var(--border-color);
      padding: 6px 8px;
      border-radius: 20px;
      background-color: var(--bg-color);

      img {
        margin-right: 5px;
      }
    }

    &--completed {
      background-color: colors.get-color(colors.$Green, level_7);

      color: colors.get-color(colors.$colors, white);
      @extend .lesson-planner-list--title--pending;
    }

    &--progress {
      background-color: colors.get-color(colors.$amber, level_7);
      @extend .lesson-planner-list--title--pending;
      color: colors.get-color(colors.$colors, white);
    }
  }

  &--subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed var(--border-color);
    padding-left: 40px;

    &--flex {
      display: flex;
      flex-direction: column;

      img {
        width: 20px;
        margin-right: 5px;
      }
    }

    &--duration {
      display: flex;
      column-gap: 5px;
      align-items: center;

      span {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        color: colors.get-color(colors.$cool_grey, level_5);
      }

      b {
        font-size: 13px;
        font-family: fonts.$eduate-number-font;
        color: colors.get-color(colors.$colors, black);
      }

      img {
        width: 15px;
      }
    }

    &--images {
      display: flex;
      column-gap: 5px;

      img {
        width: 25px;
      }
    }

    &--edit {
      @include updateButton();

      img {
        filter: invert(100%);
      }
    }

    &--set {
      @include setButton();
    }
  }

  &__tracker {
    height: 100%;

    &--content {
      height: calc(100% - 45px);
    }

    &--title {
      .Heading {
        text-align: start !important;
      }
    }
    &--field {
      display: grid;
      grid-template-columns: 1fr 2fr;
      margin: 0px 10px;
    }
  }
}

.set-duration {
  &__title {
    display: flex;

    img {
      @include closeIconInModal();
    }
  }
}

@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.agent-std-assoc {
  @include mainFrameWithTitle();
  &__select {
    column-gap: 20px;
    height: 90px;
    .data-fetch-icon {
      margin-top: 8px;
    }
    &--textfield {
      margin: 10px 0px 0px 0px !important;
      @include textfield();
    }
    &--flex {
      display: flex;
      .MuiAutocomplete-root {
        margin-top: 8px;
      }
    }
  }
  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 185px);
  }
  &__filters {
    column-gap: 10px;
    .MuiAutocomplete-root {
      margin-top: 10px;
    }
  }
  &__allocate {
    height: 100%;
    margin: 0px 20px;
    &--title {
      img {
        @include closeIconInModal();
      }
    }
    &--filters {
      column-gap: 10px;
      .MuiAutocomplete-root {
        margin-top: 10px;
      }
    }
    &--tableblock {
      margin-top: 10px;
      height: calc(100% - 130px);
    }
  }
}

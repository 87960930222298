@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.enter-key {
  height: calc(100% - 40px);
  margin: 20px;

  &__data {
    height: 100%;
    border: 1px solid var(--border-color);
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;

    &--login {
      margin-top: 10px;
      text-align: center;
      .Label {
        font-size: 20px !important;
      }
      &--instinfo {
        margin: 10px 0px 20px 0px;
        height: 90px;
      }
    }

    &--info {
      .Label {
        font-size: 20px !important;
        color: colors.get-color(colors.$grey, level_5);
      }
    }

    &--block {
      height: 300px;
      padding: 40px;
      width: 30%;
      border: 1px solid var(--disabled-color);
      border-radius: 30px;
      background-color: var(--disabled-color);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &--key {
        width: 70%;
        .storybook-button {
          background-color: colors.get-color(colors.$Green, level_7) !important;
          margin-top: 10px !important;
        }
        &--button {
          border-radius: 6px;
          margin-top: 10px;
          color: white;
          padding: 3px;
          text-transform: capitalize;
          background-color: colors.get-color(colors.$Green, level_7);
          text-align: center;
          height: 32px;
          button {
            background-color: transparent;
            color: var(--bg-color);
            font-family: fonts.$eduate-font-family;
            font-style: normal;
            font-weight: 700;
            font-size: fonts.$eduate-default-font-size;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .enter-key {
    margin: 10px;
    &__data {
      border-style: none;
      justify-content: start;

      &--login {
        .Heading {
          font-size: 20px !important;
        }
        .Label {
          font-size: 16px !important;
        }
      }

      &--info {
        text-align: center;
        .Label {
          font-size: 16px !important;
          margin-top: 0px !important;
        }
      }

      &--block {
        padding: 20px;
        width: 90%;

        &--key {
          width: 100%;
          .Label {
            color: colors.get-color(colors.$colors, gray) !important;
          }
        }
      }
    }
  }
  .ReactModal__Content--after-open {
    width: 75% !important;
  }
}

@media only screen and (max-width: 767px) {
  .enter-key {
    margin: 10px;
    &__data {
      border-style: none;
      justify-content: start;

      &--login {
        .Heading {
          font-size: 20px !important;
        }
        .Label {
          font-size: 16px !important;
        }
      }

      &--info {
        text-align: center;
        .Label {
          font-size: 16px !important;
          margin-top: 0px !important;
        }
      }

      &--block {
        padding: 20px;
        width: 90%;

        &--key {
          width: 100%;
          .Label {
            color: colors.get-color(colors.$colors, gray) !important;
          }
        }
      }
    }
  }
  .ReactModal__Content--after-open {
    width: 75% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .enter-key {
    margin: 10px;
    &__data {
      border-style: none;
      justify-content: start;

      &--login {
        .Heading {
          font-size: 22px !important;
        }
        .Label {
          font-size: 18px !important;
        }
      }

      &--info {
        text-align: center;
        .Label {
          font-size: 18px !important;
          margin-top: 0px !important;
        }
      }

      &--block {
        padding: 20px;
        width: 90%;

        &--key {
          .Label {
            font-size: 16px !important;
          }
          width: 100%;
          .Label {
            color: colors.get-color(colors.$colors, gray) !important;
          }
        }
      }
    }
  }
  .ReactModal__Content--after-open {
    width: 75% !important;
  }
}

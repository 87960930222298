@use "../../scss/common/fonts" as fonts;
@use "../../scss/common/colors" as colors;

.storybook-button {
  font-family: fonts.$eduate-font-family;
  font-style: normal;
  font-weight: 700;
  font-size: fonts.$eduate-default-font-size;
  border: 0;
  border-radius: 6px;
  margin: 10px 10px 0px 0px;
  color: white;
  padding: 5px;
  text-transform: capitalize;

  img {
    width: 25px;
    padding-right: 2px;
    filter: invert(0%);
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    box-shadow: 2px 1px 5px rgba(0, 0, 0, 0.5), 6px 5px 5px rgba(0, 0, 0, 0.5);
  }
}

.storybook-button--default {
  background-color: var(--level-8);

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//primary-color
.storybook-button--addnew,
.storybook-button--collect-fine,
.storybook-button--copy-demand,
.storybook-button--copy,
.storybook-button--create,
.storybook-button--copy-test,
.storybook-button--insert,
.storybook-button--pre-defined,
.storybook-button--re-concile,
.storybook-button--rewrite-demand,
.storybook-button--generate,
.storybook-button--pending,
.storybook-button--user-rights,
.storybook-button--passout-students,
.storybook-button--transfer-receipt,
.storybook-button--add-depositledgers,
.storybook-button--add-doctype,
.storybook-button--pay-only-ob,
.storybook-button--send,
.storybook-button--pay-newdemand-also,
.storybook-button--history {
  background-color: var(--level-8);

  &:hover {
    background-color: var(--level-4) !important;
  }

  &:focus {
    background-color: var(--level-7) !important;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.storybook-button--user-rights {
  img {
    filter: invert(100%);
  }
}

//sky
.storybook-button--edit,
.storybook-button--update,
.storybook-button--update-continue,
.storybook-button--skip,
.storybook-button--permission,
.storybook-button--move,
.storybook-button--predefined-type,
.storybook-button--ledger-report,
.storybook-button--collection-report,
.storybook-button--reserve-book,
.storybook-button--renewal-book,
.storybook-button--previous,
.storybook-button--assign-salary-structure {
  background-color: colors.get-color(colors.$sky, level_6);

  &:hover {
    background-color: colors.get-color(colors.$sky, level_5);
  }

  &:focus {
    background-color: colors.get-color(colors.$sky, level_8);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//red

.storybook-button--day-end,
.storybook-button--unclear-entries,
.storybook-button--delete,
.storybook-button--reject,
.storybook-button--Reject,
.storybook-button--remove,
.storybook-button--absent,
.storybook-button--fill-diary,
.storybook-button--no {
  background-color: colors.get-color(colors.$red, level_7);

  &:hover {
    background-color: colors.get-color(colors.$red, level_6);
  }

  &:focus {
    background-color: colors.get-color(colors.$red, level_8);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//fuchsia
.storybook-button--view,
.storybook-button--change-image,
.storybook-button--fee-details,
.storybook-button--selected,
.storybook-button--view-graph {
  background-color: colors.get-color(colors.$fuchsia, level_8);

  &:hover {
    background-color: colors.get-color(colors.$fuchsia, level_5);
  }

  &:focus {
    background-color: colors.get-color(colors.$fuchsia, level_10);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//navy-blue
.storybook-button--clear,
.storybook-button--change-visitor-type {
  background-color: #053e7a;

  &:hover {
    background-color: #4f7eb0;
  }

  &:focus {
    background-color: #052e59;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//pink
.storybook-button--demand {
  background-color: colors.get-color(colors.$pink, level_6);

  &:hover {
    background-color: colors.get-color(colors.$pink, level_5);
  }

  &:focus {
    background-color: colors.get-color(colors.$pink, level_9);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//violet
.storybook-button--print,
.storybook-button--reorder,
.storybook-button--statement,
.storybook-button--demand-details,
.storybook-button--previous-receipt,
.storybook-button--forward {
  background-color: colors.get-color(colors.$violet, level_6);

  &:hover {
    background-color: colors.get-color(colors.$violet, level_5);
  }

  &:focus {
    background-color: colors.get-color(colors.$violet, level_10);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//cyan
.storybook-button--ledger,
.storybook-button--account-ledger {
  background-color: colors.get-color(colors.$cyan, level_6);

  &:hover {
    background-color: colors.get-color(colors.$cyan, level_5);
  }

  &:focus {
    background-color: colors.get-color(colors.$cyan, level_10);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//black
.storybook-button--export,
.storybook-button--excel,
.storybook-button--submit-approval,
.storybook-button--pdf,
.storybook-button--grade,
.storybook-button--extend,
.storybook-button--capture {
  background-color: rgba(0, 0, 0, 0.8);

  &:hover {
    background: rgba(0, 0, 0, 0.48);
  }

  &:focus {
    background: rgba(0, 0, 0, 0.92);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//Green
.storybook-button--pay-online,
.storybook-button--add-new-fee,
.storybook-button--waive-off,
.storybook-button--active,
.storybook-button--fetch,
.storybook-button--received,
.storybook-button--transfer,
.storybook-button--assign,
.storybook-button--check-in,
.storybook-button--visitor-entry,
.storybook-button--move-to,
.storybook-button--make-active,
.storybook-button--allocate,
.storybook-button--yes,
.storybook-button--cash-receipts,
.storybook-button--bank-receipts,
.storybook-button--re-calculate,
.storybook-button--start-posting,
.storybook-button--next,
.storybook-button--adjustment-receipts {
  background-color: colors.get-color(colors.$Green, level_8);

  &:hover {
    background-color: colors.get-color(colors.$Green, level_6);
  }

  &:focus {
    background-color: colors.get-color(colors.$Green, level_10) !important;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//yellow

.storybook-button--back,
.storybook-button--pending-approval,
.storybook-button--cancel,
.storybook-button--reconcil-entries {
  background-color: colors.get-color(colors.$yellow, level_6);

  &:hover {
    background-color: colors.get-color(colors.$yellow, level_4);
  }

  &:focus {
    background-color: colors.get-color(colors.$yellow, level_7);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

//teal
.storybook-button--all-transaction,
.storybook-button--all-configure,
.storybook-button--log-sheet,
.storybook-button--submit,
.storybook-button--Submit,
.storybook-button--page-config,
.storybook-button--finish,
.storybook-button--issue-book,
.storybook-button--make-elligible,
.storybook-button--return-book,
.storybook-button--save,
.storybook-button--save-continue,
.storybook-button--select,
.storybook-button--save-order,
.storybook-button--configure,
.storybook-button--show-summarized,
.storybook-button--all-receipts,
.storybook-button--okay,
.storybook-button--approve,
.storybook-button--Approve,
.storybook-button--pay-fee {
  background-color: colors.get-color(colors.$teal, level_6);

  &:hover {
    background-color: colors.get-color(colors.$teal, level_4);
  }

  &:focus {
    background-color: colors.get-color(colors.$teal, level_8) !important;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.storybook-button--sign-in {
  background-color: colors.get-color(colors.$Green, level_6);
  border-radius: 6px;
  padding: 8px;
  width: 100%;

  &:hover {
    background-color: colors.get-color(colors.$Green, level_5);
  }

  &:focus {
    background-color: colors.get-color(colors.$Green, level_8);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.storybook-button--otp {
  background-color: colors.get-color(colors.$amber, level_6);
  border-radius: 30px;
  padding: 8px;
  width: 100% !important;

  &:hover {
    background-color: colors.get-color(colors.$amber, level_5);
  }

  &:focus {
    background-color: colors.get-color(colors.$amber, level_8);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.storybook-button--sign-out {
  background-color: colors.get-color(colors.$red, level_6);
  border-radius: 20px;
  padding: 6px;
  width: 100%;

  &:hover {
    background-color: colors.get-color(colors.$red, level_5);
  }

  &:focus {
    background-color: colors.get-color(colors.$red, level_8);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.storybook-button--upload {
  background-color: colors.get-color(colors.$sky, level_6);

  &:hover {
    background-color: colors.get-color(colors.$sky, level_5);
  }

  &:focus {
    background-color: colors.get-color(colors.$sky, level_8);
  }

  img {
    filter: invert(100%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.storybook-button--preview {
  background-color: #6fee22;

  &:hover {
    background-color: colors.get-color(colors.$Green, level_5);
  }

  &:focus {
    background-color: colors.get-color(colors.$Green, level_8);
  }

  img {
    filter: invert(100%);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.storybook-button--reset {
  background-color: #fa5b49;

  &:hover {
    background-color: #fe7464;
  }

  &:focus {
    background-color: #ef3e33;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.storybook-button--grade {
  img {
    filter: invert(100%);
  }
}

.storybook-button--close {
  background-color: colors.get-color(colors.$red, level_6);

  &:hover {
    background-color: colors.get-color(colors.$red, level_5);
  }

  &:focus {
    background-color: colors.get-color(colors.$red, level_7);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.storybook-button--continue,
.storybook-button--proceed {
  color: white;
  background-color: transparent;
  display: flex;
  margin-top: 20px;
  justify-content: center;
  column-gap: 10px;
  width: 100%;
  img {
    width: 20px;
  }

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    text-decoration: underline;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.storybook-button--docs-upload {
  background-color: white;
  border-radius: 20px;
  width: 100px;
  font-weight: 500;
  color: var(--text-color);
  border: 1px solid colors.get-color(colors.$grey, level_3);
  img {
    margin-top: 0px;
    width: 20px;
    margin-left: 5px;
  }
}

.storybook-button--docs-delete {
  background-color: colors.get-color(colors.$red, level_6);
  border-radius: 20px;
  width: 100px;
  font-weight: 500;
  color: white;
  border: 1px solid colors.get-color(colors.$grey, level_3);
  img {
    margin-top: 0px;
    width: 20px;
    margin-left: 5px;
  }
}

.storybook-button--message {
  background-color: colors.get-color(colors.$cool_grey, level_5);

  &:hover {
    background-color: colors.get-color(colors.$cool_grey, level_5);
  }

  &:focus {
    background-color: colors.get-color(colors.$cool_grey, level_10);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  img {
    filter: invert(100%);
  }
}
.storybook-button--history {
  img {
    filter: invert(100%);
  }
}
.storybook-button--change-image,
.storybook-button--capture {
  width: 100%;
  margin: 0px !important;
  border-radius: 0px 0px 0px 6px;
}

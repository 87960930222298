@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.payment-success {
  height: calc(100% - 40px);
  margin: 20px;

  &__instinfo {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    height: 90px;
  }
  &__data {
    display: flex;
    margin-top: 10px;
    height: calc(100% - 180px);
    border: 1px solid var(--border-color);
    border-radius: 30px;
    &--filled {
      width: 50%;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      background-color: colors.get-color(colors.$Green, level_1);
      &--details {
        margin-top: 10px;
        margin-left: 40px;
        .Heading {
          text-align: left;
        }
      }
    }
    &--fee {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: colors.get-color(colors.$Green, level_7);
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      &--details {
        border-radius: 30px;
        background-color: var(--border-color);
        height: 300px;
        width: 35%;
        padding: 30px;
        &--image {
          display: flex;
          justify-content: center;
          margin-top: 10px;
          img {
            height: 70px;
          }
        }
        &--title {
          margin: 10px 0px;
          .Heading {
            font-size: 30px !important;
          }
        }
        &--money {
          color: colors.get-color(colors.$Green, level_7);
          font-size: 30px !important;
          display: flex;
          justify-content: center;
        }
        &--button {
          display: flex;
          justify-content: center;
          height: 45px;
          column-gap: 10px;
          button {
            background-color: colors.get-color(colors.$blue, level_11);
            color: var(--bg-color);
            margin-top: 5px;
            padding: 0px 20px;
            font-size: 14px;
          }
        }
      }
    }
    &--fee-failed {
      @extend .payment-success__data--fee;
      background-color: colors.get-color(colors.$red, level_7);
    }
  }

  &__divider {
    border-bottom: 2px dashed var(--border-color);
  }

  &--info {
    background-color: var(--level-1);
    height: calc(100% - 45px);
    &--center {
      height: calc(100% - 45px);
      width: 70%;
      display: block;
      margin: auto;
      &--details {
        font-family: fonts.$eduate-font-family;
        display: grid;
        grid-template-columns: 0.5fr 0.1fr 1fr;
        margin-top: 15px;
        .Label {
          margin: 0px !important;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .payment-success__data {
    flex-direction: column-reverse;
    overflow: auto;
    row-gap: 10px;
    border-style: none;
    &--filled {
      width: 100% !important;
      border-radius: 30px;
      background-color: colors.get-color(colors.$Green, level_1);
    }
    &--fee {
      width: 100% !important;
      border-radius: 30px;
      padding: 15px 10px 15px 10px;
      &--details {
        border-radius: 30px;
        width: 95%;
        padding: 20px;
        &--image {
          margin-top: 20px;
        }
        &--title {
          .Heading {
            font-size: 22px !important;
          }
        }
        &--money {
          font-size: 22px !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .payment-success__data {
    flex-direction: column-reverse;
    overflow: auto;
    row-gap: 10px;
    border-style: none;
    &--filled {
      width: 100% !important;
      border-radius: 30px;
      background-color: colors.get-color(colors.$Green, level_1);
    }
    &--fee {
      width: 100% !important;
      border-radius: 30px;
      padding: 15px 10px 15px 10px;
      &--details {
        border-radius: 30px;
        width: 95%;
        padding: 20px;
        &--image {
          margin-top: 20px;
        }
        &--title {
          .Heading {
            font-size: 22px !important;
          }
        }
        &--money {
          font-size: 22px !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .payment-success__data {
    flex-direction: column-reverse;
    overflow: auto;
    row-gap: 10px;
    border-style: none;
    &--filled {
      width: 100% !important;
      border-radius: 30px;
      background-color: colors.get-color(colors.$Green, level_1);
    }
    &--fee {
      width: 100% !important;
      border-radius: 30px;
      padding: 20px 5px;
      &--details {
        border-radius: 30px;
        width: 95%;
        padding: 20px;
        &--image {
          margin-top: 20px;
        }
        &--title {
          .Heading {
            font-size: 22px !important;
          }
        }
        &--money {
          font-size: 22px !important;
        }
      }
    }
  }
}

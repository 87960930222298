@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.visitor-dictionary {
  height: 100%;

  &__options {
    margin-top: 5px;
    column-gap: 10px;
  }

  &__date {
    display: flex;
    justify-content: center;
    .MuiTypography-root {
      height: fit-content !important;
    }
    &--month {
      display: flex;
      justify-content: center;
      column-gap: 10px;
      margin-top: 10px;
      align-items: center;
      border: 1px solid var(--border-color);
      border-radius: 6px;
      width: fit-content;
      img {
        width: 20px;
      }
    }
  }

  &__invert-color {
    img {
      filter: invert(100%);
    }
  }

  &__invert-color-direction {
    img {
      filter: invert(100%);
      transform: rotate(180deg);
    }
  }

  .storybook-button {
    margin-right: 0px !important;
  }

  &__table {
    margin-top: 10px;
    height: calc(100% - 85px);
    border: 1px solid (--border-color);
  }

  &__new-data {
    height: 100%;
    &--title {
      display: flex;
      justify-content: flex-start !important;
    }
    &--type {
      display: grid;
      grid-template-columns: 1fr 1.3fr;
    }
    &--details {
      height: 40px;
      display: grid;
      grid-template-columns: 1fr 1fr 0.3fr;
      column-gap: 10px;
    }

    .report-card-create {
      margin: 0px !important;
    }
  }

  &__input {
    height: calc(100% - 125px);
    &--input {
      height: calc(100% - 125px);
    }
    &--exists {
      height: 30px;
      margin-top: 10px;
      background-color: colors.get-color(colors.$red, level_1);
      border-radius: 6px;
      &--alert {
        color: colors.get-color(colors.$red, level_6);
        display: flex;
        justify-content: center;
        font-style: fonts.$eduate-font-family;
      }
    }

    &--new-user {
      height: 30px;
      margin-top: 10px;
      background-color: colors.get-color(colors.$emarald, level_1);
      border-radius: 6px;
      &--alert {
        color: colors.get-color(colors.$emarald, level_6);
        display: flex;
        justify-content: center;
        font-style: fonts.$eduate-font-family;
      }
    }

    &--old-details {
      height: 120px;
      margin-top: 10px;
      column-gap: 10px;
      &--textfield {
        @include textfield();
        margin: 8px 10px 0px 0px !important;
      }
      .MuiInputBase-root {
        width: 280px !important;
      }
      &--image {
        @include studentProfile();
        height: 100%;
        margin-top: 6px;
        img {
          height: calc(100% - 10px);
          width: calc(100% - 10px);
        }
      }
    }

    &--checkin {
      height: calc(100% - 205px);
      overflow: auto;
      background-color: colors.get-color(colors.$blue, level_1);
      &--title {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        color: colors.get-color(colors.$blue, level_6) !important;
        font-style: fonts.$eduate-font-family;
      }
      &--details {
        margin: 10px 0px 0px 10px !important;
        display: grid;
        grid-template-columns: 0.38fr 0.56fr;
        &--teacher {
          display: flex;
        }
        .data-fetch-icon {
          margin-top: 10px !important;
        }
      }
      &--photo {
        margin-top: 10px;
        height: 200px;
        width: 240px;
        &--image {
          border: 1px solid var(--border-color);
          border-top-left-radius: 6px;
          height: calc(100% - 45px);
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    &--details {
      height: calc(100% - 75px);
      &--data {
        display: grid;
        grid-template-columns: 0.38fr 0.66fr;
      }
    }
  }
}

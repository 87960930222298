@use "../../../scss/common/mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;
.proprietor-dashboard {
  height: calc(100% - 175px);
  width: calc(100% - 320px);

  &__admissions {
    height: calc(100% - 175px);
  }

  &__row {
    height: 40px;

    &--flex {
      display: flex;
      justify-content: flex-end;
    }

    Input {
      height: 35px !important;
    }

    &--total {
      display: flex;
      border: 1px solid var(--border-color);
      margin-top: 10px;
      width: fit-content;
      justify-content: flex-end;
      border-radius: 6px;
      height: 35px;
      column-gap: 15px;

      img {
        width: 30px;
        height: 30px;
        padding: 5px;
      }

      .Label {
        margin-top: 0px !important;
      }
    }
  }

  &__tableblock {
    height: calc(100% - 50px);
    margin-top: 10px;

    tr:hover {
      .proprietor-dashboard__table--name {
        @include tdContainsOnclick();
      }
    }
  }

  &__table {
    @include table();

    &--total {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;

      &--boys {
        @extend .proprietor-dashboard__table--total;
        background-color: colors.get-color(colors.$fuchsia, level_7);
        color: colors.get-color(colors.$colors, white) !important;
        font-weight: 700 !important;
        text-align: right !important;
      }

      &--girls {
        @extend .proprietor-dashboard__table--total--boys;
        background-color: colors.get-color(colors.$pink, level_6);
      }
    }

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--name {
      img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
    }
  }

  &__inst-image {
    width: 25px;
  }
}

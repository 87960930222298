@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.login-details {
  height: calc(100% - 40px);
  margin: 20px;

  &__instinfo {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    height: 90px;
  }
  &__data {
    margin-top: 10px;
    display: flex;
    height: calc(100% - 180px);
    border: 1px solid var(--border-color);
    border-radius: 30px;
    &--filled {
      width: 50%;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      background-color: colors.get-color(colors.$Green, level_1);
      &--details {
        margin-top: 10px;
        margin-left: 40px;
        .Heading {
          text-align: left;
        }
      }
    }
    &--fee {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &--details {
        border-radius: 30px;
        background-color: var(--border-color);
        height: 250px;
        width: 50%;
        padding: 40px;
        &--title {
          margin: 10px 0px;
          .Heading {
            font-size: 30px !important;
          }
        }
        &--money {
          color: colors.get-color(colors.$Green, level_7);
          font-size: 30px !important;
          display: flex;
          justify-content: center;
        }
        &--button {
          margin-top: 10px;
          display: flex;
          justify-content: center;
          height: 45px;
          column-gap: 10px;
          button {
            background-color: var(--bg-color);
            color: colors.get-color(colors.$blue, level_11);
            margin-top: 5px;
            padding: 0px 20px;
            border: 2px solid colors.get-color(colors.$blue, level_11);
          }
          :hover {
            color: var(--bg-color);
            background-color: colors.get-color(colors.$blue, level_11);
          }
          &--filter {
            .storybook-button {
              img {
                filter: invert(100%) !important;
              }
            }
          }
        }
      }
    }
  }

  &__divider {
    border-bottom: 2px dashed var(--border-color);
  }

  &--info {
    background-color: var(--level-1);
    height: calc(100% - 45px);
    &--center {
      height: calc(100% - 45px);
      width: 70%;
      display: block;
      margin: auto;
      &--details {
        font-family: fonts.$eduate-font-family;
        display: grid;
        grid-template-columns: 0.5fr 0.1fr 1fr;
        margin-top: 15px;
        .Label {
          margin: 0px !important;
        }
      }
    }
  }

  &__modal {
    height: calc(100% - 110px);
    &--image {
      display: flex;
      justify-content: center;
    }
    &--text {
      text-align: center;
      margin-top: 10px;
      color: colors.get-color(colors.$grey, level_5);
    }
  }
  &__button-proceed {
    .storybook-button {
      background-color: colors.get-color(colors.$Green, level_7) !important;
      width: 100% !important;
    }
  }
  &__button-cancel {
    .storybook-button {
      width: 100% !important;
    }
  }
}

@media (max-width: 600px) {
  .login-details {
    .Heading {
      font-size: 16px !important;
    }

    &__instinfo {
      margin-bottom: 10px;
    }

    &__data {
      display: flex;
      flex-direction: column-reverse;
      overflow: auto;
      row-gap: 10px;
      height: calc(100% - 160px);
      border-style: none;
      &--filled {
        width: 100% !important;
        border-radius: 30px;
      }
      &--fee {
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--disabled-color);
        padding: 20px;
        border-radius: 30px;
        &--details {
          height: 150px;
          width: 100%;
          padding: 20px;
          &--title {
            margin: 0px !important;
            .Heading {
              font-size: 22px !important;
            }
          }
          &--money {
            font-size: 22px !important;
          }
          &--button {
            margin-top: 0px;
          }
        }
      }
    }
  }
  .login-details__modal--text {
    font-size: 14px !important;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .login-details {
    .Heading {
      font-size: 18px !important;
    }

    &__instinfo {
      margin-bottom: 10px;
    }
    &__data {
      display: flex;
      flex-direction: column-reverse;
      overflow: auto;
      row-gap: 10px;
      height: calc(100% - 160px);
      border-style: none;
      &--filled {
        width: 100% !important;
        border-radius: 30px;
      }
      &--fee {
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--disabled-color);
        padding: 20px;
        border-radius: 30px;
        &--details {
          height: 150px;
          width: 100%;
          padding: 20px;
          &--title {
            margin: 0px !important;

            .Heading {
              font-size: 18px !important;
            }
          }
          &--money {
            font-size: 22px !important;
          }
          &--button {
            margin-top: 0px;
          }
        }
      }
    }
  }
  .login-details__modal--text {
    font-size: 14px !important;
    margin-top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .login-details {
    .Heading {
      font-size: 20px !important;
    }

    &__instinfo {
      margin-bottom: 10px;
    }
    &__data {
      display: flex;
      flex-direction: column-reverse;
      overflow: auto;
      row-gap: 10px;
      height: calc(100% - 160px);
      border-style: none;
      &--filled {
        width: 100% !important;
        border-radius: 30px;
      }
      &--fee {
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--disabled-color);
        padding: 20px;
        border-radius: 30px;
        &--details {
          height: 150px;
          width: 100%;
          padding: 20px;
          &--title {
            margin: 0px !important;
            .Heading {
              font-size: 20px !important;
            }
          }
          &--money {
            font-size: 22px !important;
          }
          &--button {
            margin-top: 0px;
          }
        }
      }
    }
  }
  .login-details__modal--text {
    font-size: 14px !important;
    margin-top: 40px;
  }
}

@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.all-yr-ledger-summary {
  @include mainFrameWithTitle();
  &__block {
    height: calc(100% - 45px);
    overflow: auto;

    &--header {
      display: flex;
      justify-content: space-around;
      align-items: center;
      outline: none;
      &:focus {
        outline: none;
      }
     

      &--flex {
        display: grid;
        column-gap: 60px;
        align-items: center;
        grid-template-columns: repeat(7, 1fr);
        background-color: white;
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
          0px 2px 4px -1px rgba(0, 0, 0, 0.06);
        border-radius: 6px;
        margin-top: 10px;
        padding: 10px;
        border: 1px solid var(--border-color);
      }
      &--text {
        font-family: fonts.$eduate-font-family;
        font-size: 14px;
        color: colors.get-color(colors.$cool_grey, level_8);
        display: block;
      }

      &--block {
        display: block;
        text-align: center;
      }
      &--b {
        background-color: var(--level-6);
        color: white;
        padding: 10px;
        font-family: fonts.$eduate-number-font;
        font-size: 16px;
        border-radius: 5px;
      }
      &--amount {
        font-family: fonts.$eduate-number-font;
        font-size: 16px;
      }
    }
  }
  &__header {
    border: 1px solid var(--border-color);
    border-radius: 6px;
    padding: 8px 15px;
    margin-top: 10px;
    &--var {
      background-color: var(--level-1);
      @extend .all-yr-ledger-summary__header;
    }
    &--flex {
      display: flex;
      column-gap: 20px;
      align-items: center;
    }
    &--text {
      font-family: fonts.$eduate-font-family;
      font-size: 18px;
      color: colors.get-color(colors.$cool_grey, level_8);
      display: block;
    }


    &--block {
      display: block;
      text-align: center;
    }
    &--b {
      background-color: var(--level-6);
      color: white;
      padding: 10px;
      font-family: fonts.$eduate-number-font;
      font-size: 16px;
      border-radius: 5px;
    }
    &--amount {
      font-family: fonts.$eduate-number-font;
      font-size: 18px;
    }
    &--grid {
      display: flex;
      justify-content: space-between;
      align-items: center;
      outline: none;
      &:focus {
        outline: none;
      }
    }
    &--b {
      font-size: 16px;
      font-family: fonts.$eduate-font-family;
      padding: 8px 5px;
    }
  }
  &__content {
    display: flex;
    column-gap: 40px;
    margin-top: 10px;
    &--block {
    //   background-color: white;
      border-radius: 6px;
      padding: 10px;
      width: 50%;
      b {
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-family: fonts.$eduate-font-family;
      }
    }
    &--tableblock {
      margin-top: 10px;
    }

  }
}

.all-yr-ledger-summary__block::-webkit-scrollbar {
  @include scrollbar();
}

.all-yr-ledger-summary__block::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.all-yr-ledger-summary__block::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.test-list-view{
    @include mainFrameWithTitle();
    &__filters{
        column-gap: 10px;
        margin-top: 10px;
    }
    &__title{
        display: flex;
        justify-content: center;
        column-gap: 10px;
        margin-top: 5px;
        margin-bottom: 15px;
        b{
            color: var(--text-color);
            font-size: 20px;
            font-family: fonts.$eduate-font-family;

        }
    
     
    }
    &__tableblock{
        height: calc(100% - 135px);
        margin-top:10px;
    }
    &__table{
        @include table();
    }
}
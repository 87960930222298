@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.status {
  height: 100%;
  &__details {
    height: calc(100% - 75px);
    column-gap: 20px;
    &--filled {
      height: calc(100% - 40px);
      margin-top: 20px;
      border: 1px solid var(--border-color);
      border-top-right-radius: 30px;
      &--title {
        margin-top: 30px;
        .Heading {
          font-size: 18px !important;
        }
      }
      &--fields {
        margin: 0px 20px;
        display: grid;
        grid-template-columns: 1fr 2fr;
        column-gap: 20px;
        &--student {
          display: flex;
        }
        .info {
          margin-top: 10px;
        }
      }
    }
    &--fill {
      height: calc(100% - 40px);
      margin-top: 20px;
      border: 1px solid var(--border-color);
      border-top-left-radius: 30px;
      &--title {
        margin: 10px 0px;
        .Heading {
          font-size: 18px !important;
        }
      }
      &--fields {
        margin: 0px 20px;
        display: grid;
        grid-template-columns: 1fr 2fr;
        column-gap: 20px;
      }
    }
  }
}

@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;

.ttt {
  &__tableblock {
    margin-top: 20px;
    @include tableInPrint();
    border-left: 1px solid var(--text-color) !important;

    th {
      border-right: 1px solid var(--text-color) !important;
      padding: 5px !important;
      text-align: center;
    }

    td {
      border-bottom: 1px solid var(--text-color) !important;
      padding: 5px !important;
      font-size: 18px !important;
    }
  }
  &__std-list {
    @include mainFrameWithTitle();
    &--tableblock {
      height: calc(100% - 80px);
      margin-top: 10px;
    }
  }
  &__title {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    b {
      color: var(--text-color);
      font-size: 20px;
      font-family: fonts.$eduate-font-family;
    }
    &--test {
      display: block;
      color: var(--text-color);
      font-size: 18px;
      font-family: fonts.$eduate-font-family;
      text-align: center;
    }
  }
  &__footer {
    margin-top: 20px;

    color: var(--text-color);
    font-size: 16px;
    font-family: fonts.$eduate-font-family;
  }

  &__std-details {
    display: flex;
    font-family: fonts.$eduate-font-family;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    column-gap: 10px;
    justify-content: space-between;
    border-top: 1px solid var(--text-color);
    &--grid {
      display: grid;
      column-gap: 20px;
      grid-template-columns: 1fr 0.1fr 2fr;
      input {
        font-weight: 700;
      }
    }
    &--grid2 {
      display: grid;
      column-gap: 20px;
      grid-template-columns: 1.5fr 0.1fr 1.5fr;
      input {
        font-weight: 700;
      }
    }
    &--image {
      width: 60px;
      height: 60px;
      img {
        @include studentProfile();
        height: 100%;
        width: 100%;
      }
    }
    &--flex {
      display: flex;
      column-gap: 10px;
    }
    &--block {
      display: block;
      margin: auto;
    }
  }
}

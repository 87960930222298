@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.std-visitor-details {
  height: calc(100% - 75px);
  margin: 10px 0px 10px 0px;
  background-color: var(--level-1) !important;

  .MuiAutocomplete-option {
    &:hover {
      background-color: var(--level-2) !important;
    }

    &:focus {
      background-color: var(--level-2) !important;
    }

    height: 10px !important;
  }

  &__register {
    height: 100%;

    &--form {
      margin: 0px 20px 0px 20px;
      &--logo {
        height: 50px;
        display: flex;
        justify-content: center;
      }
      &--button {
        display: grid;
        grid-template-columns: 1.5fr 0.1fr;
        column-gap: 10px;
        .storybook-button {
          margin: 10px 0px 0px 0px !important;
          height: 30px !important;
        }
      }
    }
  }

  &__request {
    height: calc(100% - 50px);
    background-color: colors.get-color(colors.$amber, level_1);

    &--heading {
      margin: 0px 20px 10px 20px;
      &--logo {
        height: 50px;
        display: flex;
        justify-content: center;
        padding-top: 10px;
        .Heading {
          margin-top: 5px !important;
        }
      }
      .react-tel-input {
        top: 0px !important;
      }
    }

    &--checkin {
      height: 250px;
      background-color: var(--bg-color);
      margin: 0px 20px 0px 20px;
      border-radius: 6px;
      &--title {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        font-style: fonts.$eduate-font-family;
        padding-top: 10px;
      }
      &--details {
        margin: 10px 20px 0px 20px !important;
        display: grid;
        grid-template-columns: 0.38fr 0.56fr;
      }
    }
  }
  .media-button {
    margin-left: 20px;
  }

  .visitor-dictionary__new-data--type {
    display: block !important;
    .MuiInputBase-input {
      background-color: var(--bg-color) !important;
    }
    .FormMyAutocomplete-formControlRoot {
      margin-top: 0px !important;
    }
    .Label {
      padding: 0px !important;
      margin-top: 0px !important;
    }
    .react-tel-input {
      top: 0px !important;
    }
    .storybook-button {
      margin: 0px !important;
    }
  }

  .visitor-dictionary__input--new {
    height: 100% !important;
  }
}

@media (max-width: 600px) {
  .std-visitor-details {
    height: calc(100% - 20px) !important;
  }
  .inst-details {
    height: 80px !important;
    background-color: var(--bg-color);
  }
  .std-visitor-details__register {
    height: calc(100% - 145px) !important;
    &--form {
      .Heading {
        font-size: 16px !important;
      }
      .Label {
        padding: 0px !important;
        margin-top: 0px !important;
      }
      .react-tel-input {
        top: 0px !important;
      }
      .storybook-button {
        margin: 0px !important;
      }
      &--logo {
        height: fit-content !important;
      }
      &--button {
        grid-template-columns: 1fr 0.3fr;
      }
    }
  }
  .visitor-dictionary__input {
    height: calc(100% - 115px);
    background-color: var(--level-1) !important;

    .Label {
      margin-top: 0px !important;
      padding: 0px !important;
    }
    .storybook-textarea {
      margin: 0px !important;
      padding: 0px !important;
    }
    .storybook-input {
      margin-top: 0px !important;
    }

    &--old-details {
      .MuiInputBase-root {
        width: auto !important;
      }
      margin: 0px 10px 0px 20px;
    }
    &--checkin {
      height: calc(100% - 220px);
      background-color: var(--bg-color) !important;
      margin: 10px 20px 0px 20px;
      border-radius: 6px;
      &--details {
        display: grid;
        grid-template-columns: 0.4fr 0.56fr;
      }
    }
    &--exists {
      margin: 10px 20px 0px 20px;
    }
    &--new-user {
      margin: 10px 20px 0px 20px;
    }
    &--details {
      height: calc(100% - 90px);
      overflow: auto;
      margin: 0px 20px 0px 20px;
      .storybook-input {
        margin-top: 0px !important;
      }
      .MuiAutocomplete-root .FormMyAutocomplete-formControlRoot {
        margin-top: 0px !important;
        background-color: var(--bg-color);
      }

      &--data {
        display: grid;
        grid-template-columns: 1fr 0.66fr;
        height: 100%;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .std-visitor-details {
    height: calc(100% - 20px) !important;
  }
  .inst-details {
    height: 80px !important;
    background-color: var(--bg-color);
  }
  .std-visitor-details__register {
    height: calc(100% - 125px) !important;
    &--form {
      .Heading {
        font-size: 16px !important;
      }
      .Label {
        padding: 0px !important;
        margin-top: 0px !important;
      }
      .react-tel-input {
        top: 0px !important;
      }
      .storybook-button {
        margin: 0px !important;
      }
      &--logo {
        height: fit-content !important;
      }
      &--button {
        grid-template-columns: 1fr 0.3fr;
      }
    }
  }
  .visitor-dictionary__input--new {
    height: 100% !important;
  }
  .visitor-dictionary__input--input {
    height: calc(100% - 120px) !important;
  }

  .visitor-dictionary__input--checkin--new {
    height: calc(100% - 135px) !important;
  }

  .visitor-dictionary__input--details {
    .Label {
      margin-top: 0px !important;
      padding: 0px !important;
    }
    .storybook-textarea {
      margin: 0px !important;
      padding: 0px !important;
    }
    .storybook-input {
      margin-top: 0px !important;
    }
  }
  .visitor-dictionary__input {
    height: calc(100% - 65px);
    background-color: var(--level-1) !important;

    .Label {
      margin-top: 0px !important;
      padding: 0px !important;
    }
    .storybook-textarea {
      margin: 0px !important;
      padding: 0px !important;
    }
    .storybook-input {
      margin-top: 0px !important;
    }

    &--old-details {
      .MuiInputBase-root {
        width: auto !important;
      }
      margin: 0px 10px 0px 20px;
    }
    &--checkin {
      height: calc(100% - 220px);
      background-color: var(--bg-color) !important;
      margin: 10px 20px 0px 20px;
      border-radius: 6px;
      &--details {
        display: grid;
        grid-template-columns: 0.4fr 0.56fr;
      }

      .Label {
        margin-top: 5px !important;
        padding: 5px !important;
      }
      .storybook-textarea {
        margin: 0px !important;
        padding: 0px !important;
      }
      .storybook-input {
        margin-top: 5px !important;
      }
    }
    &--exists {
      margin: 10px 20px 0px 20px;
    }
    &--new-user {
      margin: 10px 20px 0px 20px;
    }
    &--details {
      height: calc(100% - 90px);
      overflow: auto;
      margin: 0px 20px 0px 20px;
      .storybook-input {
        margin-top: 0px !important;
      }
      .MuiAutocomplete-root .FormMyAutocomplete-formControlRoot {
        margin-top: 0px !important;
        background-color: var(--bg-color);
      }

      &--data {
        display: grid;
        grid-template-columns: 1fr 0.66fr;
        height: 100%;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .std-visitor-details {
    height: calc(100% - 20px) !important;
  }

  .inst-details {
    height: 65px !important;
    background-color: var(--bg-color);
  }

  .std-visitor-details__register {
    height: calc(100% - 145px) !important;
    &--form {
      .Heading {
        font-size: 16px !important;
      }
      .Label {
        padding: 0px !important;
        margin-top: 0px !important;
      }
      .react-tel-input {
        top: 0px !important;
      }
      .storybook-button {
        margin: 0px !important;
      }
      &--button {
        grid-template-columns: 0.9fr 0.1fr;
        .storybook-button {
          margin-top: 0px !important;
        }
      }
    }
  }
  .react-tel-input {
    top: 0px !important;
  }

  .visitor-dictionary__input--new {
    height: 100% !important;
  }

  .visitor-dictionary__input--checkin--new {
    height: calc(100% - 135px) !important;
  }

  .visitor-dictionary__input--details {
    .Label {
      margin-top: 0px !important;
      padding: 0px !important;
    }
    .storybook-textarea {
      margin: 0px !important;
      padding: 0px !important;
    }
    .storybook-input {
      margin-top: 0px !important;
    }
  }
  .visitor-dictionary__input--input {
    height: calc(100% - 105px) !important;
  }

  .visitor-dictionary__input {
    height: calc(100% - 90px);

    .Label {
      margin-top: 0px !important;
      padding: 0px !important;
    }
    .storybook-textarea {
      margin: 0px !important;
      padding: 0px !important;
    }
    .storybook-input {
      margin-top: 0px !important;
    }
    &--exists {
      margin: 10px 10px 0px 10px;
    }
    &--old-details {
      margin: 10px 0px 0px 10px;
    }
    &--checkin {
      height: calc(100% - 190px);
      background-color: var(--bg-color) !important;
      margin: 10px 10px 0px 10px;
      border-radius: 6px;
      &--details {
        margin: 10px 10px 0px 10px !important;
        display: block;
      }

      .Label {
        margin-top: 5px !important;
        padding: 5px !important;
      }
      .storybook-textarea {
        margin: 0px !important;
        padding: 0px !important;
      }
      .storybook-input {
        margin-top: 5px !important;
      }
    }
    &--new-user {
      margin: 10px 20px 0px 20px;
    }
    &--details {
      height: calc(100% - 50px);
      margin: 0px 20px 0px 20px;
      .MuiAutocomplete-root .FormMyAutocomplete-formControlRoot {
        margin-top: 0px !important;
        background-color: var(--bg-color);
      }
    }
  }
  .std-visitor-details__request--checkin {
    &--title {
      font-size: 16px;
    }
    &--details {
      display: block;
    }
  }
}

.visitor-qrcode {
  height: calc(100% - 45px);
  &__inst {
    height: 100px;
    margin-bottom: 10px;
    .inst-details {
      height: 100% !important;
      &__data {
        height: 100%;
        width: 67%;
        justify-content: space-between;
        &--logo {
          height: 100% !important;
          width: 100px !important;
          img {
            width: 80px;
            height: 80px;
          }
        }
        &--address {
          margin-top: 10px;
          b {
            font-size: 14px !important;
            font-weight: 700;
          }
          span {
            font-size: 14px !important;
            font-weight: 700;
          }
        }
        &--c-name {
          font-weight: 700;
        }
      }
    }
  }
  .Heading {
    margin: 10px 0px !important;
  }
  .std-enquiry-print {
    &__url {
      &--footer1 {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        font-family: fonts.$eduate-font-family;
        font-weight: bold;
        padding: 10px;
      }
      &--footer2 {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        font-family: fonts.$eduate-font-family;
        font-weight: bold;
      }
    }
  }
}

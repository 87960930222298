@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.inst-details {
  height: 50px;

  &__data {
    height: fit-content;
    display: flex;
    column-gap: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dashed var(--border-color);
    padding: 0px 20px;

    &--logo {
      height: 100%;

      img {
        display: block;
        margin: auto;
        width: 50px;
        height: 50px;
      }
    }

    &--c-name {
      font-size: 10px !important;
      font-family: fonts.$eduate-font-family;
      padding: 2px;
      color: colors.get-color(colors.$grey, level__6);
    }

    &--address {
      margin-top: 5px;
      text-align: center;

      span {
        display: block;
        font-size: 10px;
        padding: 2px;
        font-family: fonts.$eduate-font-family;
        text-transform: capitalize;
      }

      b {
        display: block;
        font-size: 12px;
        padding: 2px;
        font-family: fonts.$eduate-font-family;
      }
    }
  }
}

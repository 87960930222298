@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.parent-dashboard {
  height: 100%;
  &__nav {
    height: 30px;
    background-color: var(--level-6);
    border-radius: 0px 0px 25px 0px;
  }
  &__frame {
    height: calc(100% - 140px);
    overflow: auto;
    align-items: stretch;
    align-content: stretch;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;

    &--student-list {
      margin-top: 10px;
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 4px 6px -2px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      border: 1px solid colors.get-color(colors.$grey, level_3);
      padding: 10px;
      width: 23%;
      height: calc(100% - 390px);
      overflow: auto;
      Label {
        margin-top: 0px !important;
      }

      &:hover {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1),
          0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4);
      }
      li {
        display: flex;
        justify-content: space-between;
        height: 35px;
      }
      &--more-options {
        display: flex;
        justify-content: space-between;
        &--student-profile {
          display: block;
          margin: auto;
          height: 100px;
          width: 100px;
          @include studentProfile();
        }
      }
    }
  }
  &__multiple-student {
    height: calc(100% - 35px);
    overflow: auto;
    &--block {
      border-bottom: 1px dotted var(--level-4);
      margin-bottom: 10px;
      height: 80px;
      padding: 5px;
      column-gap: 20px;

      &--image {
        @include studentProfile();
        height: 100%;
        img {
          width: calc(100% - 20px);
        }
      }
      &--details {
        li {
          display: flex;
          justify-content: space-between;
          Label {
            margin-top: 0px !important;
          }
        }
      }
    }
  }
}

.parent-dashboard__multiple-student::-webkit-scrollbar {
  @include scrollbar();
}

.parent-dashboard__multiple-student::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.parent-dashboard__multiple-student::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.feedemand__delete {
  @include mainFrameWithTitle();
  &--block {
    height: 125px;
    .account-frames {
      margin: 0px 0px 0px 0px !important;
    }
    .MuiFormControl-root {
      margin-top: 3px !important;
    }

    Label {
      margin-top: 5px;
    }
  }

  &--tableblock {
    margin-top: 10px;
    height: calc(100% - 180px);
  }

  &--table {
    @include table();
    @include tableCheckbox();
    height: calc(100% - 30px) !important;
    margin-top: 5px;

    &--regno {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--amt {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-regno {
      width: calc(100% - 90%);
    }

    &--th-amt {
      width: calc(100% - 90%);
    }

    &--actions {
      width: calc(100% - 95%);
    }

    &--desc {
      width: calc(100% - 92%);
    }
  }

  &--buttons {
    margin-left: 10px;
  }

  &--slno {
    width: calc(100% - 96%);
  }

  &--total {
    text-align: right;
  }
}

@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.documents-content {
  height: 100%;
  .quill {
    border: 1px solid var(--border-color) !important;
    margin-top: 10px !important;
  }
  &__title {
    img {
      @include closeIconInModal();
    }
  }

  &__data {
    height: calc(100% - 75px);
    .ql-editor{
      height: 400PX !important;
    }
  }
  &__predefined-words {
    margin-top: 10px;
    height: calc(100% - 445px);
    overflow: auto;

    button {
      background-color: var(--level-2);
      padding: 5px 10px;
      border-radius: 30px;
      font-family: fonts.$eduate-font-family;
      font-size: fonts.$eduate-default-font-size;
      margin: 5px;
    }
  }
}

.documents-content__predefined-words::-webkit-scrollbar {
  @include scrollbar();
}

.documents-content__predefined-words::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.documents-content__predefined-words::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.registration-details {
  height: calc(100% - 20px);
  margin: 10px 10px 10px 10px;
  &__inst {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    height: 90px;
  }
  &__online-form {
    height: calc(100% - 175px);
  }
  &__bg {
    height: calc(100% - 220px);
    margin: 10px 40px 0px 40px;
    background-color: var(--border-color);
    border: 1px solid var(--border-color);
    border-radius: 30px;
  }
  &__form {
    height: calc(100% - 60px);
    background-color: var(--bg-color);
    margin: 30px;
    display: flex;
    &--image {
      height: 100%;
      img {
        height: 100% !important;
        width: 100% !important;
      }
    }
  }
  &__data {
    margin: 10px;
    column-gap: 10px;
    display: flex;
    &--student {
      width: 50%;
      .Heading {
        text-align: left !important;
      }
      &--names {
        display: flex;
        column-gap: 10px;
      }

      &--label-grid {
        display: grid;
        grid-template-columns: 8fr 1fr;
        margin-top: 5px;
      }
      .label-grid {
        grid-template-columns: 1fr;
        margin: 5px 0px;
      }
    }
    &--seeking {
      width: 50%;
      .Heading {
        text-align: left !important;
      }
    }
    &--agent {
      margin-top: 10px;
    }
  }
  &__buttons {
    margin: 0px 0px 0px 20px;
  }
  &__modal {
    height: calc(100% - 90px);
    &--image {
      display: flex;
      justify-content: center;
    }
    &--text {
      text-align: center;
      margin-top: 10px;
      color: colors.get-color(colors.$grey, level_5);
    }
  }
  &__modal-buttons {
    .storybook-button {
      width: 100% !important;
    }
  }
}

@media (max-width: 600px) {
  .registration-details {
    height: 100%;
    overflow: auto;
    padding: 10px;
    background-color: var(--border-color);
    margin: 0px !important;
    .Heading {
      font-size: 20px !important;
    }
    &__bg {
      height: 100%;
      margin: 10px 0px 0px 0px !important;
      border-style: none;
      border-radius: none;
    }
    &__form {
      height: calc(100% - 10px);
      margin: 0px 20px !important;
      display: flex;
      flex-direction: column;
      overflow: auto;
      row-gap: 10px;
      background-color: var(--border-color);
      &--image {
        height: 150px;
        background-color: var(--border-color);
        img {
          height: 100% !important;
          width: 100% !important;
        }
      }
    }
    &__data {
      margin: 0px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      background-color: var(--border-color);

      .storybook-input {
        margin-top: 0px !important;
      }
      .MuiFormControl-fullWidth {
        margin-top: 0px !important;
      }
      .react-tel-input {
        top: 0px !important;
      }
      .Label {
        margin-top: 0px !important;
        padding: 0px !important;
      }
      &--student {
        .MuiFormControl-root {
          margin-top: 0px !important;
        }
        .MuiAutocomplete-root {
          margin-top: 0px !important;
        }
        width: 100% !important;
        padding: 20px;
        background-color: var(--bg-color);

        &--dob {
          .storybook-input {
            width: 100% !important;
          }
        }
      }
      &--seeking {
        .MuiFormControl-root {
          margin-top: 0px !important;
        }
        .MuiTextField-root {
          margin-top: 5px !important;
        }
        padding: 10px;
        width: 100% !important;
        background-color: var(--bg-color);
      }
      &--agent {
        .MuiFormControl-root {
          margin-top: 0px !important;
        }
        background-color: var(--bg-color);
      }
      &--captcha {
        .MuiTextField-root {
          margin-top: 5px !important;
        }
        .change-password__textfield--captcha {
          margin-bottom: 5px !important;
        }
      }
    }
    &__buttons {
      margin: 0px 0px 0px 20px;
      justify-content: flex-start;
    }
  }
  .ReactModal__Content--after-open {
    width: 75% !important;
  }
}

@media only screen and (max-width: 767px) {
  .registration-details {
    height: 100%;
    overflow: auto;
    padding: 10px;
    background-color: var(--border-color);
    margin: 0px !important;
    .Heading {
      font-size: 20px !important;
    }
    &__bg {
      height: 100%;
      margin: 10px 0px 0px 0px !important;
      border-style: none;
      border-radius: none;
    }
    &__form {
      height: calc(100% - 10px);
      margin: 0px 20px !important;
      display: flex;
      flex-direction: column;
      overflow: auto;
      row-gap: 10px;
      background-color: var(--border-color);
      &--image {
        height: 150px !important;
        background-color: var(--border-color);
        img {
          height: 100% !important;
          width: 100% !important;
        }
      }
    }
    &__data {
      margin: 0px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      background-color: var(--border-color);

      .storybook-input {
        margin-top: 0px !important;
      }
      .MuiFormControl-fullWidth {
        margin-top: 0px !important;
      }
      .react-tel-input {
        top: 0px !important;
      }
      .Label {
        margin-top: 0px !important;
        padding: 0px !important;
      }
      &--student {
        .MuiFormControl-root {
          margin-top: 0px !important;
        }
        .MuiAutocomplete-root {
          margin-top: 0px !important;
        }
        width: 100% !important;
        padding: 20px;
        background-color: var(--bg-color);

        &--dob {
          width: 100% !important;
        }
      }
      &--seeking {
        .MuiFormControl-root {
          margin-top: 0px !important;
        }

        padding: 10px;
        width: 100% !important;
        background-color: var(--bg-color);
      }
      &--agent {
        .MuiFormControl-root {
          margin-top: 0px !important;
        }
        background-color: var(--bg-color);
      }
      &--captcha {
        .MuiTextField-root {
          margin-top: 5px !important;
        }
      }
    }
    &__buttons {
      margin: 0px 0px 0px 20px;
      justify-content: flex-start;
    }
  }
  .ReactModal__Content--after-open {
    width: 75% !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .registration-details {
    height: 100%;
    overflow: auto;
    padding: 10px;
    background-color: var(--border-color);
    margin: 0px !important;
    .Heading {
      font-size: 20px !important;
    }

    &__bg {
      height: 100%;
      margin: 10px 0px 0px 0px !important;
      border-style: none;
      border-radius: none;
    }
    &__form {
      height: calc(100% - 10px);
      margin: 0px 20px !important;
      display: flex;
      flex-direction: column;
      overflow: auto;
      row-gap: 10px;
      background-color: var(--border-color);
      &--image {
        height: 250px !important;
        background-color: var(--border-color);
        img {
          height: 100% !important;
          width: 100% !important;
        }
      }
    }
    &__data {
      margin: 0px;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      background-color: var(--border-color);
      .MuiFormControl-root {
        margin-top: 0px !important;
      }
      .storybook-input {
        margin-top: 0px !important;
      }
      .MuiFormControl-fullWidth {
        margin-top: 0px !important;
      }
      .react-tel-input {
        top: 0px !important;
      }
      .Label {
        margin-top: 0px !important;
        padding: 0px !important;
      }
      &--student {
        .MuiFormControl-root {
          margin-top: 0px !important;
        }
        .MuiAutocomplete-root {
          margin-top: 0px !important;
        }
        width: 100% !important;
        padding: 20px;
        background-color: var(--bg-color);

        &--dob {
          width: 100% !important;
        }
      }
      &--seeking {
        .MuiFormControl-root {
          margin-top: 0px !important;
        }
        padding: 10px;
        width: 100% !important;
        background-color: var(--bg-color);
      }
      &--agent {
        .MuiFormControl-root {
          margin-top: 0px !important;
        }
        background-color: var(--bg-color);
      }
      &--captcha {
        .MuiTextField-root {
          margin-top: 5px !important;
        }
      }
    }
    &__buttons {
      margin: 0px 0px 0px 20px;
      justify-content: flex-start;
    }
  }
  .ReactModal__Content--after-open {
    width: 75% !important;
  }
}

@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
$YELLOW_COLOR: "FFF200";
$PINK_COLOR: "EC008C";
.bgs-pn-pr {
  width: 100%;
  height: calc(100% - 75px);
  overflow: auto;
  .page-break {
    page-break-after: always !important;
  }

  &__block {
    width: 100%;
    height: 100%;
  }
  &__inst-details {
    display: flex;
    align-items: center;
    column-gap: 10px;
    border-bottom: 1px dashed var(--text-color);
    padding-bottom: 5px;
    column-gap: 20px;
    &--logo {
      width: 85px;
      height: 85px;
    }
    &--inst-name {
      font-family: fonts.$eduate-font-family;
      display: flex;
    }
    &--cust-name {
      font-family: fonts.$eduate-font-family;
      display: flex;
    }
    &--trust {
      font-family: fonts.$eduate-font-family;
      display: flex;
    }
  }
  &__std-details {
    display: flex;
    font-family: fonts.$eduate-font-family;
    padding-top: 5px;
    padding-bottom: 5px;
    column-gap: 20px;
    justify-content: space-between;
    &--grid {
      display: grid;
      column-gap: 20px;
      grid-template-columns: 1fr 0.1fr 2fr;
      input {
        font-weight: 700;
      }
    }
    &--grid2 {
      display: grid;
      column-gap: 20px;
      grid-template-columns: .8fr 0.01fr 1.5fr;
      input {
        font-weight: 700;
      }
    }
    &--image {
      width: 60px;
      height: 60px;
      img {
        @include studentProfile();
        height: 100%;
        width: 100%;
      }
    }
  }
  &__annual-results {
    margin-top: 10px;
    b {
      font-size: 22px;
      font-family: fonts.$eduate-font-family;
      display: flex;
      justify-content: center;
    }
    &--status {
      font-size: 18px;
      font-family: fonts.$eduate-font-family;
      display: flex;
      justify-content: center;
    }
    &--to {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      font-size: 14px;
      font-family: fonts.$eduate-font-family;
      margin-top: 5px;

      b {
        font-weight: 700;
        font-size: 18px;
        border-bottom: 1px solid var(--text-color);
        border-radius: 0px !important;
        border-style: none;
        width: fit-content;
      }
    }
  }
  &__signatures {
    td {
      font-size: 16px !important;
    }
    &--principal {
      height: 28px;
      width: fit-content;
    }
    &--sign {
      width: 20% !important;
    }
  }
  &__non-acd {
    &--row {
      th {
        font-size: 20px !important;
      }
    }
    &--table {
      border-left: 1px solid var(--text-color) !important;

      th {
        border-top: none !important;
        border-right: 1px solid var(--text-color) !important;
        padding: 2px 5px !important;
      }

      td {
        border-bottom: 1px solid var(--text-color) !important;
        padding: 2px 5px !important;
      }

      .header-color{
        th{
          background-color: var(--level-3) !important;
        }
        td{
          background-color: var(--level-3) !important;
        }
      }
    }
  }
  &__acd-details {
    &--table {
      border-left: 1px solid var(--text-color) !important;

      th {
        border-top: 1px solid var(--text-color) !important;
        border-right: 1px solid var(--text-color) !important;
        padding: 2px 5px !important;
        border-bottom: 1px solid var(--text-color) !important;
        font-family: fonts.$table-font-family;
        font-weight: 700;
      }

      td {
        border-bottom: 1px solid var(--text-color) !important;
        padding: 2px 5px !important;
        padding-left: 3px;
        padding-right: 3px;
        color: var(--text-color);
        border-right: 1px solid var(--text-color) !important;
        font-family: fonts.$table-font-family;

      }

      // @include tableInPrint();
      .header-color{
        th,td{
          background-color: var(--level-3) !important;
        }
      }
    }
    &--title{
      font-size: 16px;
      font-family: fonts.$eduate-font-family;
    }
  }
}

.admission-ticket {
  padding: 40px;
  border: 1px solid var(--text-color);


  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: fonts.$eduate-font-family;
    margin-top: 15px;
    b {
      border: 1px solid var(--text-color);
      width: fit-content;
      padding: 2px 10px;
      font-size: 24px;
    }
  }
  .ttt__std-details {
    border-style: none;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  .progress-report__inst--details--cust-name {
    font-size: 18px !important;
  }
  .progress-report__inst--details--inst-name {
    font-size: 30px !important;
  }
  .progress-report__inst--details--inst-address{
    font-size: 18px !important;
    font-family: fonts.$eduate-font-family;
  }
  .ttt__std-details--grid {
    grid-template-columns: 0.4fr 0.1fr 1fr;
    padding: 5px;
    label {
      font-size: 22px;
    }
    input {
      font-size: 22px;
    }
  }
  .progress-report__inst--logo{
    width: 150px !important;
    height: 150px !important;
  }
}

.bgs-pn-pr::-webkit-scrollbar {
  @include scrollbar();
}

.bgs-pn-pr::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.bgs-pn-pr::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

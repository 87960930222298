@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.transaction-success {
  height: 100%;
  &__footer {
    height: 80px;
    border-radius: 5px;
    background-color: colors.get-color(colors.$cool_grey, level_2);
    margin-top: 20px;
    padding: 10px;
    &--flex {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      label {
        font-family: fonts.$eduate-font-family;
        font-size: 14px;
        color: var(--text-color);
      }
      span {
        font-family: fonts.$eduate-font-family;
        font-size: 14px;
        color: var(--level-5);
        font-weight: 600;
      }
      b {
        font-family: fonts.$eduate-font-family;
        font-size: 14px;
        color: colors.get-color(colors.$cool_grey, level_8);
        font-weight: 400;
      }
    }
  }
  &__title {
    img {
      @include closeIconInModal();
    }
  }
  &__datablock {
    display: flex;
    column-gap: 10px;
    margin-top: 10px;
    height: calc(100% - 185px);
    &--right {
      background-color: var(--level-1);
      width: 50%;
      height: 100%;
      border-radius: 6px;
      padding: 10px;

      ul {
        margin-bottom: 0px;
        height: calc(100% - 100px);
        margin-top: 10px;
        background-color: var(--bg-color);
        padding: 20px;
        margin: 20px 40px;
        border-radius: 6px;
      }
      li {
        display: grid;
        grid-template-columns: 1fr 0.1fr 1fr;
        padding: 10px;
        label {
          font-size: 12px;
          color: colors.get-color(colors.$cool_grey, level_6);
          font-family: fonts.$eduate-font-family;
        }
        span {
          font-size: 14px;
          color: var(--text-color);
          text-align: right;
          font-family: fonts.$eduate-number-font;
        }
      }
    }
    &--left {
      width: 50%;
      height: 100%;

      img {
        height: 350px;
        width: 350px;
        display: block;
        margin: auto;
      }
    }
    &--text {
      color: colors.get-color(colors.$Green, level_6);
      font-family: fonts.$eduate-number-font;
      font-size: 16px;
      text-align: center;
      display: block;
    }
    &--subtext {
      color: var(--text-color);
      font-size: 14px;
      text-align: center;
      display: block;
      font-family: fonts.$eduate-font-family;
    }
  }
}

@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.add-entry {
  height: 100%;

  .report-card-create {
    margin: 0px !important;
  }

  &__new-data {
    &--title {
      display: flex;
      justify-content: flex-start !important;
    }
    &--details {
      height: 90px;
      &--view {
        display: grid;
        grid-template-columns: 1fr 1.3fr;
      }

      &--data {
        height: 40px;
        display: grid;
        grid-template-columns: 1fr 1fr 0.3fr;
        column-gap: 10px;
      }

      // &--teacher {
      //   display: flex;
      // }
      // &--type {
      //   display: grid;
      //   grid-template-columns: 0.6fr 0.41fr;
      //   column-gap: 10px;
      //   .storybook-button {
      //     margin-right: 0px !important;
      //   }
      // }
      // .data-fetch-icon {
      //   margin-top: 10px !important;
      // }
    }
  }
  &__input {
    height: calc(100% - 45px);
    &--input {
      height: calc(100% - 130px);
    }
    &--exists {
      height: 30px;
      margin-top: 10px;
      background-color: colors.get-color(colors.$red, level_1);
      border-radius: 6px;
      &--alert {
        color: colors.get-color(colors.$red, level_6);
        display: flex;
        justify-content: center;
        font-style: fonts.$eduate-font-family;
      }
    }
    &--new-user {
      height: 30px;
      margin-top: 10px;
      background-color: colors.get-color(colors.$emarald, level_1);
      border-radius: 6px;
      &--alert {
        color: colors.get-color(colors.$emarald, level_6);
        display: flex;
        justify-content: center;
        font-style: fonts.$eduate-font-family;
      }
    }
    &--old-details {
      height: 120px;
      margin-top: 10px;
      column-gap: 10px;
      &--textfield {
        @include textfield();
        margin: 8px 10px 0px 0px !important;
      }
      .MuiInputBase-root {
        width: 280px !important;
      }
      &--image {
        @include studentProfile();
        height: 100%;
        margin-top: 6px;
        img {
          height: calc(100% - 10px);
          width: calc(100% - 10px);
        }
      }
    }
    &--checkin {
      height: calc(100% - 205px);
      overflow: auto;
      background-color: colors.get-color(colors.$blue, level_1);
      &--title {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        color: colors.get-color(colors.$blue, level_6) !important;
        font-style: fonts.$eduate-font-family;
      }
      &--details {
        margin: 10px 0px 0px 10px !important;
        display: grid;
        grid-template-columns: 0.38fr 0.56fr;
        &--teacher {
          display: flex;
        }
        .data-fetch-icon {
          margin-top: 10px !important;
        }
      }
      &--photo {
        margin-top: 10px;
        height: 200px;
        width: 240px;
        &--image {
          border: 1px solid var(--border-color);
          border-top-left-radius: 6px;
          height: calc(100% - 45px);
          img {
            height: 100%;
            width: 100%;
          }
        }
      }
    }

    &--details {
      height: 100%;
      &--data {
        display: grid;
        grid-template-columns: 0.38fr 0.66fr;
      }
    }
  }

  &__visitor {
    height: calc(100% - 210px);
    &--new-user {
      height: 30px;
      margin-top: 10px;
      background-color: var(--level-1);
      border-radius: 6px;
      &--alert {
        color: var(--level-6);
        display: flex;
        justify-content: center;
        font-family: fonts.$eduate-font-family;
        font-size: 14px;
      }
    }
  }

  &__table {
    margin-top: 10px;
    height: 250px;
  }

  &__button {
    @include assignButton();
    .storybook-button {
      margin: 0px !important;
    }
  }

  &__visitor-data {
    border: 1px solid var(--border-color);
    border-radius: 6px;
    margin-top: 10px;

    &--old-details {
      height: 120px;
      margin-left: 10px;
      column-gap: 10px;
      &--textfield {
        @include textfield();
        margin: 8px 10px 0px 0px !important;
      }
      .MuiInputBase-root {
        width: 280px !important;
      }
      &--image {
        @include studentProfile();
        height: 100%;
        margin-top: 6px;
        img {
          height: calc(100% - 10px);
          width: calc(100% - 10px);
        }
      }
    }
  }

  &__checkin {
    margin-top: 10px;
    height: calc(100% - 270px);
    overflow: auto;
    background-color: colors.get-color(colors.$blue, level_1);
    &--title {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      color: colors.get-color(colors.$blue, level_6) !important;
      font-family: fonts.$eduate-font-family;
      font-size: 14px;
    }
    &--details {
      margin: 10px 0px 0px 10px !important;
      display: grid;
      grid-template-columns: 0.38fr 0.56fr;
      &--teacher {
        display: flex;
      }
      .data-fetch-icon {
        margin-top: 10px !important;
      }
    }
    &--photo {
      margin-top: 10px;
      height: 200px;
      width: 240px;
      &--image {
        border: 1px solid var(--border-color);
        border-top-left-radius: 6px;
        height: calc(100% - 45px);
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  &__student-table {
    height: calc(100% - 140px);
    margin-top: 10px;
  }

  &__clear-button {
    margin: 0px !important;
  }

  &__options {
    column-gap: 10px;
    margin-top: 10px;
  }
}

@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.details-submitted {
  height: calc(100% - 40px);
  margin: 20px;
  &__content {
    height: 100%;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;
    .Heading {
      font-size: 30px !important;
    }

    &--sub-heading {
      .Heading {
        color: colors.get-color(colors.$grey, level_5) !important;
      }
    }

    &--image {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
  }
}

@media (max-width: 600px) {
  .details-submitted {
    &__content {
      img {
        height: 250px;
      }
      &--sub-heading {
        height: 130px !important;
        .Heading {
          font-size: 22px !important;
        }
      }
      &--image {
        img {
          height: 50px;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .details-submitted {
    &__content {
      img {
        height: 250px;
      }
      &--sub-heading {
        height: 130px !important;
        .Heading {
          font-size: 22px !important;
        }
      }
      &--image {
        img {
          height: 50px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .details-submitted {
    &__content {
      img {
        height: 250px;
      }
      &--sub-heading {
        height: 90px !important;
        .Heading {
          font-size: 22px !important;
        }
      }
      &--image {
        img {
          height: 50px;
        }
      }
    }
  }
}

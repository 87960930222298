@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.id-template-1 {
  height: 100%;
  margin: 0px 20px;

  &__card {
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
    height: calc(100% - 55px);
    padding: 5px;

    &--fs {
      height: calc(100% - 10px);
      margin-top: 10px;
      width: 50%;

      &--li {
        width: 100%;
        height: 350px;
        margin-bottom: 10px;

     
        .id-template-1__card--fs--id--c-name,
        .id-template-1__card--fs--id--inst-name {
          font-size: 8px;
        }

        .id-template-1__card--fs--id--std-image {
          width: 40px;
          height: 40px;
        }

        .id-template-1__card--fs--id--grid {
          label,
          span,
          b {
            font-size: 10px;
          }
        }
      }

      &--id {
        border: 1px solid var(--border-color);
        height: calc(100% - 30px);
        background: var(--level-1);
        border-radius: 6px;
        &--inst-logo {
          width: 45px;
          height: 45px;

          img {
            width: 45px;
            height: 45px;
          }
        }
        &--profile {
          padding: 5px;
          height: calc(100% - 30px);
          overflow: hidden;
        }

        &--inst-details {
          display: flex;
          column-gap: 5px;
          height: fit-content;
        }

        &--grid {
          display: grid;
          grid-template-columns: 1fr 0.1fr 1fr;

          label,
          span,
          b {
            font-size: 10px;
            margin-top: 2px;
            font-family: fonts.$eduate-font-family;
            font-weight: 400;
          }

          &--addr {
            margin-top: 2px;
            height: 70px;
            overflow: hidden;

            label,
            span {
              font-size: 12px;
              font-family: fonts.$eduate-font-family;
            }
          }
        }

        &--std-image {
          width: 80px !important;
          height: 80px !important;
          display: block;
          margin-left: auto;
          margin-right: auto;

          img {
            width: 80px !important;
            height: 80px !important;
          }
        }

        &--footer {
          height: 30px;
          background-color: var(--level-3);
          display: flex;
          justify-content: center;

          align-items: center;

          span {
            font-size: fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-font-family;
          }
        }

        &--c-name {
          font-size: 8px;
          font-family: fonts.$eduate-font-family;
        }

        &--inst-name {
          font-size: 8px;
          font-family: fonts.$eduate-font-family;
          text-align: center;
          display: block;
        }

     

        &--inst-address {
          height: calc(100% - 115px);
          overflow: hidden;
          margin-top: 5px;
          &--without-footer {
            height: calc(100% - 85px);
            overflow: hidden;
            margin-top: 5px;
          }
        }

        &--addr {
          font-size: 8px;
          font-family: fonts.$eduate-font-family;
          font-weight: 700;
        }

        &--signature {
          text-align: right;
          font-size: 12px;
          font-family: fonts.$eduate-font-family;
          margin-top: 25px span {
            display: block;
          }
          img {
            width: 80px;
            height: 25px;
          }
        }
        &--footer-text {
          font-family: fonts.$eduate-font-family;
          font-size: 10px;
          font-weight: 600;
          margin-top: 3px;
        }
      }
    }

    &--bs {
      @extend .id-template-1__card--fs;

      &--id {
        @extend .id-template-1__card--fs--id;
      }

      .id-template-1__card--fs--id--inst-name {
        font-size: 12px !important;
      }
    }
  }

  &__button {
    border-top: 1.8px dashed var(--border-color);

    button {
      background-color: colors.get-color(colors.$cool_grey, level_3);
      padding: 5px;
      border-radius: 20px;
      text-align: center;
      width: 100%;
      display: block;
      margin-top: 10px;
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
      font-weight: 700;
    }
  }
}

.selected-id-template {
  border: 1px solid var(--level-3);
  border-radius: 10px;
  margin: 0px 10px;

  .id-template-1 {
    margin: 0px 0px;
  }
}

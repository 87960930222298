@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.request-status {
  height: calc(100% - 75px);
  &__filters {
    column-gap: 10px;

    .MuiAutocomplete-root {
      margin-top: 10px;
    }
    &--date {
      display: flex;
      justify-content: flex-end;
      column-gap: 10px;
    }
  }
  &__textfield {
    @include textfieldInForms();
    width: fit-content !important;
  }

  &__compare {
    height: 25px;
    margin-top: 10px;
  }

  &__notify {
    margin-top: 10px;
    border-radius: 6px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: colors.get-color(colors.$cool-grey, level_2);
    b {
      color: var(--text-color);
      font-size: 14px;
      font-family: fonts.$eduate-number-font;
      padding: 3px 8px;
      border-radius: 2px;
      margin-left: 5px;
    }
    span {
      font-size: 14px;
      font-family: fonts.$eduate-font-family;
      color: white;
      font-weight: 500;
    }
    // &--text {
    //   display: flex;
    //   justify-content: center;
    //   font-family: fonts.$eduate-font-family;
    //   padding: 10px;
    // }
  }

  &__list {
    height: calc(100% - 105px);
    margin-top: 10px;
    overflow: auto;
    &--details {
      font-family: fonts.$eduate-font-family;
      align-items: center;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      border-bottom: 1px solid var(--border-color);
      padding: 5px 0px;
      &--fields {
        display: block;
      }
      &--button {
        height: 35px;
        column-gap: 10px;
      }
      &--view {
        @include viewButton();
      }
    }
  }
}
#bg-green {
  background-color: colors.get-color(colors.$Green, level_7);
  b {
    background-color: white;
    color: colors.get-color(colors.$Green, level_5);
  }
}
#bg-red {
  background-color: colors.get-color(colors.$red, level_5);
  b {
    background-color: white;
    color: colors.get-color(colors.$red, level_5);
  }
}
#bg-grey {
  background-color: colors.get-color(colors.$cool-grey, level_2);
  b {
    background-color: colors.get-color(colors.$cool-grey, level_4);
  }
  span {
    color: var(--text-color);
  }
}
#bg-var {
  background-color: var(--level-5);
  b {
    background-color: white;
    color: var(--level-5);
  }
}

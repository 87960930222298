@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.inst-info {
  &__data {
    height: fit-content;
    display: flex;
    column-gap: 10px;
    border-bottom: 1px dashed var(--border-color);
    padding: 0px 20px 10px 20px;
    align-items: center;

    &--logo {
      height: 100%;

      img {
        display: block;
        margin: auto;
        width: 70px;
        height: 70px;
      }
    }

    &--c-name {
      font-size: 10px !important;
      font-family: fonts.$eduate-font-family;
      padding: 2px;
      color: colors.get-color(colors.$grey, level__6);
    }

    &--address {
      margin-top: 5px;
      text-align: center;

      span {
        display: block;
        font-size: 16px;
        padding: 2px;
        font-family: fonts.$eduate-font-family;
        text-transform: capitalize;
      }

      b {
        display: block;
        font-size: 30px;
        padding: 2px;
        font-family: fonts.$eduate-font-family;
      }
    }
  }
}

@media (max-width: 600px) {
  .inst-info__data {
    column-gap: 0px;
    padding: 0px;
  }
  .inst-info__data--address {
    b {
      font-size: 16px !important;
    }
    span {
      font-size: 12px !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  .inst-info__data {
    column-gap: 0px;
    padding: 0px;
  }
  .inst-info__data--address {
    b {
      font-size: 16px !important;
    }
    span {
      font-size: 12px !important;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .inst-info__data {
    column-gap: 0px;
    padding: 0px;
  }
  .inst-info__data--address {
    b {
      font-size: 20px !important;
    }
    span {
      font-size: 12px !important;
    }
  }
}

@use "../../../../../scss/common/fonts" as fonts;

.rp-template-4 {
  &__remarks {
    font-size: 14px;
    margin-top: 8px;
    display: block;
    font-family: fonts.$eduate-font-family;
  }
  &__results {
    display: flex;
    column-gap: 5px;
    .bgs-pn-pr__annual-results--to {
      margin-top: 0px !important;
    }
  }
}

@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.view-checkin {
  height: 100%;

  &__title {
    display: flex;
    justify-content: flex-start !important;
  }
  &__type {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
  }
  &__details {
    height: 120px;
    margin-top: 10px;
    column-gap: 10px;
    &--textfield {
      @include textfield();
      margin: 8px 10px 0px 0px !important;
    }
    .MuiInputBase-root {
      width: 280px !important;
    }
    &--image {
      @include studentProfile();
      height: 100%;
      margin-top: 6px;
      img {
        height: calc(100% - 10px);
        width: calc(100% - 10px);
      }
    }
  }
  &__filled-data {
    height: calc(100% - 215px);
    margin-top: 10px;
    overflow: auto;
    background-color: colors.get-color(colors.$blue, level_1);
    &--title {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      color: colors.get-color(colors.$blue, level_6) !important;
      font-family: fonts.$eduate-font-family;
      font-size: 14px;
    }
    &--details {
      margin: 10px 0px 0px 10px !important;
      display: grid;
      grid-template-columns: 0.38fr 0.6fr;
      &--teacher {
        display: flex;
      }
      .data-fetch-icon {
        margin-top: 10px !important;
        img {
          vertical-align: baseline !important;
        }
      }
    }
    &--photo {
      margin-top: 10px;
      height: 200px;
      width: 240px;
      &--image {
        border: 1px solid var(--border-color);
        border-top-left-radius: 6px;
        height: calc(100% - 45px);
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  &__history {
    height: calc(100% - 10px);
    &--details {
      height: 120px;
      margin-top: 10px;
      column-gap: 10px;
      &--textfield {
        @include textfield();
        margin: 8px 10px 0px 0px !important;
      }
      &--image {
        @include studentProfile();
        height: 100%;
        margin-top: 6px;
        img {
          height: calc(100% - 10px);
          width: calc(100% - 10px);
        }
      }
    }
    &--table {
      height: calc(100% - 220px);
    }
  }
}

@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.assignments {
  height: 100%;

  &__title {
    height: 60px;
    display: flex;
    align-items: center;

    .Heading {
      text-align: left !important;
    }
  }

  .storybook-input {
    margin-top: 0px !important;
  }

  &__list {
    margin-top: 10px;
    height: calc(100% - 115px);
    overflow: auto;

    &--data {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      gap: 10px;

      &--card {
        text-align: center;
        border-radius: 6px;
        background-color: colors.get-color(colors.$Green, level_1);
        padding: 10px;
        margin-bottom: 10px;

        &--not-submitted {
          @extend .assignments__list--data--card;
          background-color: colors.get-color(colors.$red, level_1);
        }
      }

      &--b {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
      }

      &--code {
        display: block;
        font-size: 12px;
        font-family: fonts.$eduate-number-font;
      }

      &--view {
        margin-top: 10px;
        @include viewButton();
      }
    }
  }

  &__view {
    height: 100%;

    &--title {
      column-gap: 10px;

      &--back {
        font-family: fonts.$eduate-font-family;
        color: colors.get-color(colors.$grey, level_7) !important;
        display: flex;
        align-items: center;
      }
    }

    &--textfield {
      @include textfieldInForms();
    }

    &--date {
      column-gap: 10px;
    }

    &--data {
      height: calc(100% - 85px);
      overflow: auto;
    }

    &--list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      gap: 10px;

      &--cards {
        text-align: center;
        border-radius: 6px;
        background-color: colors.get-color(colors.$red, level_1);
        padding: 10px;

        &--not-submitted {
          @extend .assignments__list--data--card;
          background-color: colors.get-color(colors.$Green, level_1);
        }

        &--image {
          display: flex;
          justify-content: space-between;
          column-gap: 10px;

          img {
            width: 20px;
          }
        }

        &--b {
          font-size: fonts.$eduate-default-font-size;
          text-align: center;
        }

        &--li {
          justify-content: flex-start;
          padding: 10px;
          column-gap: 30px;

          img {
            width: 20px;
          }

          &:hover {
            background-color: var(--level-2) !important;
            border-radius: 3px;
          }

          span {
            font-size: 14px !important;
          }
        }

        &--flex {
          display: flex;
          justify-content: space-between;
        }

        &--submitted {
          &--text {
            font-size: 12px;
          }

          &--number {
            display: block;
            font-size: fonts.$eduate-default-font-size;
            color: colors.get-color(colors.$Green, level_7);
            font-weight: 700;
            font-family: fonts.$eduate-number-font;
          }
        }

        &--notsubmitted {
          &--text {
            font-size: 12px;
          }

          &--number {
            display: block;
            color: colors.get-color(colors.$red, level_7);
            font-weight: 700;
            font-size: fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-number-font;
          }
        }

        &--created {
          background-color: colors.get-color(colors.$grey, level_1);
          padding: 2px;

          &--text {
            font-size: 10px;
          }

          &--number {
            font-size: 10px;
            font-family: fonts.$eduate-number-font;
          }
        }
      }
    }

    &--mark {
      margin-top: 5px;
      @include assignButton();
    }

    &--update {
      margin-top: 5px;
      @include updateButton();
    }
  }

  &__creation {
    height: 100%;

    &--attachments {
      border: 1px solid var(--border-color);
      padding: 5px;
      margin-top: 10px;
      border-radius: 6px;
      min-height: 35px;

      li {
        font-family: fonts.$eduate-font-family;
        font-size: fonts.$eduate-default-font-size;
      }

      &--input {
        width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &--data {
      height: calc(100% - 75px);
    }

    &--title {
      display: flex;
      justify-content: space-between;
    }

    &--selected {
      text-align: center;

      span {
        font-family: fonts.$eduate-number-font;
        font-size: 12px;
        color: colors.get-color(colors.$grey, level_7);
      }
    }

    &--logo-grid {
      display: grid;
      grid-template-columns: 0.8fr 1.5fr 0.6fr;
      gap: 10px;
    }

    &--browse {
      @include browseFiles();
      margin: 10px 10px 0px 0px;

      &--input {
        display: none;
      }
    }

    .quill {
      border: 1px solid var(--border-color) !important;
      margin-top: 10px !important;
    }
  }

  &__view-details {
    @include mainFrameWithTitle();

    &--filters {
      column-gap: 10px;
      margin-top: 10px;
    }

    &--data {
      margin-top: 10px;
      height: calc(100% - 105px);
      width: 600px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    &--data-block {
      border: 1px solid var(--border-color);
      border-radius: 6px;
      height: 100%;
      background-color: colors.get-color(colors.$cool_grey, level_1);
      padding: 5px;
      overflow: auto;
    }

    &--messages-list {
      height: 100%;

      &--modal {
        height: 100%;
      }

      &--messages {
        height: calc(100% - 35px);
        overflow: auto;
        display: flex;
        padding: 5px 20px;

        ul {
          height: 100% !important;
        }

        &--list {
          display: flex;
          gap: 10px;
          border-radius: 6px;
          padding: 5px 10px;

          &--sender {
            border-radius: 6px;
            margin-right: 0% !important;
            background-color: var(--level-2) !important;
            padding: 5px 10px;
            border-radius: 6px;
            width: 100%;
            display: flex;

            &--li {
              padding: 5px;
            }
          }
        }

        &--file-image {
          width: 100%;
          height: 240px;
        }

        p {
          font-family: fonts.$eduate-font-family;
          font-size: fonts.$eduate-default-font-size;
          margin: 0px;
        }
      }

      &--image {
        width: 35px;
        height: 35px;
        margin-top: 5px;
      }

      &--time {
        font-family: fonts.$eduate-number-font;
        font-size: 12px;
        font-weight: 700;
        text-align: right;
        display: block;
      }

      &--name {
        font-family: fonts.$eduate-font-family;
        font-size: fonts.$eduate-default-font-size;
        width: 200px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &--header {
        font-size: 20px;
        display: flex;
        justify-content: center;
        font-family: fonts.$eduate-font-family;
        color: var(--text-color);
      }

      &--flex {
        display: flex;
        justify-content: space-between;
      }
    }

    &--textfield {
      @include textfieldInForms();
      width: fit-content !important;
      margin-top: 0px !important;
    }

    &--channel-name {
      color: colors.get-color(colors.$grey, level_7);
      font-size: 16px;
      font-weight: 700;
      font-family: fonts.$eduate-font-family;
    }

    &--flex {
      display: flex;
      column-gap: 10px;
      align-items: center;
    }

    &--total {
      font-family: fonts.$eduate-font-family;
      font-size: fonts.$eduate-default-font-size;
    }

    &--body {
      height: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 70%;
      @include muiChipToShowDates();

      &--li {
        width: 100%;
        margin: 10px;
        background-color: colors.get-color(colors.$colors, white);
        border-radius: 6px;
        padding: 10px 20px;
      }
    }
  }
  &__daily-activities {
    margin: 0px 20px;
    height: calc(100% - 65px);
  }
}

.teachers-channel {
  height: 100%;
  padding-top: 10px;
  &__data {
    @extend .assignments__view-details--data;
    margin-top: 0px !important;
    height: calc(100% - 45px) !important;
  }

  &__button {
    width: 600px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.assignments__list::-webkit-scrollbar,
.stu-assignments__list--ul::-webkit-scrollbar,
.assignments__view-details--data-block::-webkit-scrollbar {
  @include scrollbar();
}

.assignments__list::-webkit-scrollbar-thumb,
.stu-assignments__list--ul::-webkit-scrollbar-thumb,
.assignments__view-details--data-block::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.assignments__list::-webkit-scrollbar-thumb:hover,
.stu-assignments__list--ul::-webkit-scrollbar-thumb:hover,
.assignments__view-details--data-block::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

.stu-assignments {
  display: flex;
  height: 100%;
  column-gap: 40px;

  .subtitle1 {
    text-align: center !important;
  }

  &__list {
    height: 100%;
    width: 280px;

    &--ul {
      margin-top: 10px;
      height: calc(100% - 30px);
      overflow: auto;
    }

    &--card {
      @extend .assignments__list--data--card;
    }
  }

  &__chat-view {
    height: 100%;
    width: calc(100% - 280px);
  }
}

@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;

.update-schedule {
  margin: 10px 20px 0px 20px;
  height: calc(100% - 180px);
  &__datablock {
    height: calc(100% - 40px);
    column-gap: 40px;
    .booktype-left,
    .booktype-right {
      margin: 0px 0px 0px 0px !important;
    }

    &--label-grid {
      display: grid;
      grid-template-columns: 0.5fr 1fr;
    }
  }

  &__table {
    margin-top: 10px;
  }
}

@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.clean-up-users {
  @include mainFrameWithTitle();

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &--block {
    display: block;
    width: 300px;
    height: fit-content;
    color: var(--text-color);
    padding: 10px;
    font-family: fonts.$eduate-font-family;
    font-size: 16px;
    border:1px solid var(--border-color);
    font-weight: 400;
    border-radius: 6px;
    text-align: justify;
  }
}

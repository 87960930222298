@use "../../scss/common/mixins" as *;
@use "../../scss/common/fonts" as fonts;
@use "../../scss/common/colors" as colors;

.inst-list {
  @include mainFrameWithTitle();

  &__select {
    column-gap: 10px;
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 95px);
    border: 1px solid (--border-color);
  }
}

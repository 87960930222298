@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.capture-image {
  &__title {
    display: flex;
    img {
      @include closeIconInModal();
    }
  }
  &__photo {
    height: 470px;
    width: 580px;
    margin: 0px;
    video {
      height: 100%;
      width: 100%;
    }
  }
  .countdown {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 6rem;
    color: white; /* Or any color you prefer */
    font-weight: 700;
    z-index: 10;
  }
}

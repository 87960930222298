@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.studentlist {
  height: 100%;

  &--modal {
    height: calc(100% - 10px);
  }

  &__options {
    padding: 10px;
    margin-left: 10px;
    column-gap: 10px;

    &--modal {
      column-gap: 10px;
      padding: 10px;
    }
  }

  &__select {
    min-height: 15px !important;
    font-size: 12px;
    padding: 0px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 6px !important;
    margin-right: 20px;
  }

  &__select-columns {
    margin-right: 20px;
    @include filterColumns();

    img {
      margin-left: 0px;
    }

    Label {
      margin-top: 0px;
    }

    &--modal {
      @extend .studentlist__select-columns;
      margin-right: 0px !important;
    }
  }

  &__column-list {
    margin-top: 10px;
    height: calc(100% - 120px);
    overflow-y: auto;

    .MuiButtonBase-root,
    .MuiCheckbox-root {
      padding: 0px !important;
    }

    ul {
      margin-bottom: 0.35rem !important;
    }

    li {
      font-size:fonts.$eduate-default-font-size;
    }
  }

  &__table {
    @include table();
    @include tableCheckbox();

    tbody {
      td {
        height: 30px;
      }

      tr:hover .studentlist__table--name {
        @include tdContainsOnclick();
      }
    }

    &--slno {
      width: calc(100% - 97%);
      border-left: none !important;
      font-family: fonts.$eduate-number-font;
    }

    &--admno {
      width: calc(100% - 93%);
      font-family: fonts.$eduate-number-font;
    }

    &--action {
      width: calc(100% - 96%);
    }

    &--status {
      width: calc(100% - 96%);
    }

    &--amount {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--mobile {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--catg {
      width: calc(100% - 92%);
    }

    &--name {
      width: calc(100% - 74%);
    }

    &--fname {
      width: calc(100% - 74%);
    }

    &--more {
      width: calc(100% - 97%);
      border-left: none !important;
      border-style: none !important;

      img {
        width: 25px;
        filter: invert(0%);

        &:hover {
          background:colors.get-color(colors.$colors, white);
          border-radius: 50%;
        }
      }

      ul > li {
        display: flex;
        justify-content: flex-start;
        padding: 10px;
        gap: 30px;
        margin-bottom: 20px;
      }

      &--fee {
        margin-bottom: 10px;
        padding: 5px;

        &:hover {
          background-color: var(--level-2) !important;
          border-radius: 3px;
        }
      }

      &--demand {
        margin-bottom: 10px;
        padding: 5px;

        &:hover {
          background-color: var(--level-2) !important;
          border-radius: 3px;
        }
      }

      &--interchange {
        margin-bottom: 10px;
        padding: 5px;

        &:hover {
          background-color: var(--level-2) !important;
          border-radius: 3px;
        }
      }

      &--nondemand {
        margin-bottom: 10px;
        padding: 5px;

        &:hover {
          background-color: var(--level-2) !important;
          border-radius: 3px;
        }
      }

      &--refund {
        margin-bottom: 10px;
        padding: 5px;

        &:hover {
          background-color: var(--level-2) !important;
          border-radius: 3px;
        }
      }
    }

    &--more-header {
      img {
        filter: invert(100%);
      }
    }
  }

  &__search {
    input {
      margin-top: 0px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }

  &__filters {
    display: flex;

    .MuiFormGroup-root {
      margin-top: 7px;
    }
  }

  .buttons-flex {
    display: flex;
    justify-content: flex-end;
  }

  .student-add-button {
    margin-top: 0px;
  }

  .student-clear-button {
    margin: 0px 10px 0px 0px !important;
  }
}

#studentlist__frame {
  height: calc(100% - 265px);
  margin: 0px 20px 0px 20px;
}

#studentlist__frame--report {
  height: calc(100% - 95px);
}

#studentlist__transport {
  height: calc(100% - 265px);
  margin: 0px 20px 0px 20px;
}

#studentlist__transport--modal {
  height: calc(100% - 130px);
  margin: 10px 0px 0px 0px;
}

#studentlist__modal--frame {
  height: calc(100% - 160px);
  margin: 10px 0px 0px 0px;
}
#studentlist__modal--lib{
  height: calc(100% - 120px);

}
#aluministudentlist__frame {
  height: calc(100% - 263px);
  margin: 10px 20px 0px 20px;
}

#aluministudentlist__modal--frame {
  height: calc(100% - 123px);
  margin: 10px 0px 0px 0px;
}

.std_status {
  background-color: colors.get-color(colors.$red, level_5);
  color:colors.get-color(colors.$colors, white) !important;

  &:hover {
    background-color: colors.get-color(colors.$red, level_5);
    color:colors.get-color(colors.$colors, white) !important;
  }

  &--ne {
    background-color:colors.get-color(colors.$yellow , level_5);
    color:colors.get-color(colors.$colors, white) !important;

    &:hover {
      background-color:colors.get-color(colors.$yellow , level_5);
      color:colors.get-color(colors.$colors, white) !important;
    }
  }

  &--soa {
    background-color: colors.get-color(colors.$sky, level_5);
    color:colors.get-color(colors.$colors, white) !important;

    &:hover {
      background-color: colors.get-color(colors.$sky, level_5);
      color:colors.get-color(colors.$colors, white) !important;
    }
  }

  &--nxt {
    background-color: colors.get-color(colors.$Green, level_5);
    color:colors.get-color(colors.$colors, white) !important;

    &:hover {
      background-color: colors.get-color(colors.$Green, level_5);
      color:colors.get-color(colors.$colors, white) !important;
    }
  }

  &--det {
    background-color:colors.get-color(colors.$true_grey , level_5);
    color:colors.get-color(colors.$colors, white) !important;

    &:hover {
      background-color:colors.get-color(colors.$true_grey , level_5);
      color:colors.get-color(colors.$colors, white) !important;
    }
  }

  &--legends {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-right: 20px;
    column-gap: 5px;

    &--nxt {
      background-color: colors.get-color(colors.$Green, level_5);
      border-radius: 999px;
      height: 30px;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      padding: 5px;
      width: 40px;
      color:colors.get-color(colors.$colors, white);
    }

    &--det {
      @extend .std_status--legends--nxt;
      background-color:colors.get-color(colors.$true_grey , level_5);
    }

    &--soa {
      @extend .std_status--legends--nxt;
      background-color: colors.get-color(colors.$sky, level_5);
    }

    &--ne {
      background-color:colors.get-color(colors.$yellow , level_5);
      @extend .std_status--legends--nxt;
    }

    &--tc {
      background-color: colors.get-color(colors.$red, level_5);
      @extend .std_status--legends--nxt;
    }

    &--cur {
      @extend .std_status--legends--nxt;
      background-color: var(--level-2);
    }

    Label {
      margin-top: 6px;
      padding: 0px;
      font-size: 12px !important;
    }
  }
}

.student-total-count {
  font-size:fonts.$eduate-default-font-size;
  font-family: fonts.$eduate-font-family;
  margin: 12px 20px 0px 20px;

  b {
    padding: 5px;
    font-family: fonts.$eduate-number-font;
    background: var(--level-5);
    border-radius: 2px;
    color: white;
  }
}

.student-column {
  padding: 5px;
  border-radius: 6px;
  margin-left: 20px;
}

.student-clear-button--modal {
  display: flex !important;
  margin: 0px 20px 5px auto !important;
}

.student-clear-button--acd {
  display: flex !important;
  margin: 0px 10px 0px auto !important;
}

.studentlist__column-list::-webkit-scrollbar {
  @include scrollbar();
}

.studentlist__column-list::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.studentlist__column-list::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}


.library-import{
  .import-excel-data{
    height: calc(100% - 35px) !important;
    margin: 0px 0px !important;
  }
}
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.print-id {
  height: 100%;

  &__data {
    height: calc(100% - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &--image {
      img {
        height: 250px;
      }
    }
    &--warning {
      font-family: fonts.$eduate-font-family;
    }
  }

  &__card {
    height: 100%;
    &--info {
      background-color: var(--level-1);
      height: calc(100% - 45px);
      &--center {
        height: calc(100% - 45px);
        width: 70%;
        display: block;
        margin: auto;
        &--details {
          font-family: fonts.$eduate-font-family;
          display: grid;
          grid-template-columns: 1.5fr 0.3fr 1fr;
          margin-top: 15px;
          .Label {
            margin: 0px !important;
          }
        }
      }
    }
  }
}

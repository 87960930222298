@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.assign-lesson-planner {
  margin: 0px 20px;
  height: calc(100% - 165px);

  &__frame {
    margin-top: 10px;
    height: calc(100% - 95px);
    border: 1px solid var(--border-color);
    border-radius: 2px;
    display: flex;

    &--left {
      width: 210px;
      overflow: auto;

      &--sub {
        margin: 5px 0px;
        height: 75px;
        display: flex;
        align-items: center;
        flex-direction: column;
        img {
          height: 20px;
        }
        span {
          font-size: fonts.$eduate-default-font-size;
          font-family: fonts.$eduate-font-family;
        }

        b {
          font-size: 16px;
          font-family: fonts.$eduate-number-font;
        }

        &--selected {
          background-color: var(--level-5);
          border-radius: 6px;
          padding: 3px 10px;
          font-family: fonts.$eduate-font-family;

          span,
          b {
            display: block;
            text-align: center;
            color: colors.get-color(colors.$colors, white);
          }
        }
      }
      &--sublist {
        height: calc(100% - 85px);
        background-color: var(--level-1);
      }
    }

    &--right {
      width: calc(100% - 210px);
    }
  }

  &__subs {
    height: 100%;
    margin-bottom: 0px !important;

    &--li {
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      border-bottom: 1px dashed var(--border-color);
      padding-bottom: 5px;
      height: 75px;

      img {
        width: 20px;
      }

      span {
        display: block;
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        color: colors.get-color(colors.$cool_grey, level_6);
      }

      b {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
      }

      &--selected {
        background-color: var(--bg-color) !important;
        @extend .assign-lesson-planner__subs--li;
      }
    }
  }
}

.assign-lesson-planner__frame--left::-webkit-scrollbar {
  @include scrollbar();
}

.assign-lesson-planner__frame--left::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.assign-lesson-planner__frame--left::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

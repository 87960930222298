@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.attendance-overview {
  height: 100%;
  .MuiDrawer-paperAnchorRight {
    top: 95px !important;
    bottom: 0px !important;
    width: 30% !important;
    border-radius: 6px 0px;
  }

  &__title {
    height: 45px;
    display: flex;
    column-gap: 10px;
    align-items: center;

    &--sessions {
      padding: 5px 12px;
      border-radius: 20px;
      font-family: fonts.$eduate-font-family;
      background-color: var(--bg-color);
      font-size: 16px;
      font-weight: 700;
      display: flex;
      border: 1px solid var(--border-color);

      &--success {
        width: 20px;
        margin-right: 5px;
      }

      &--active {
        @extend .attendance-overview__title--sessions;
        color: var(--level-5);
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
          0px 4px 6px -1px rgba(0, 0, 0, 0.1);
      }

      &--close {
        width: 20px;
        margin-left: 5px;
      }

      &:hover {
        border: 1px solid var(--level-3);
      }
    }
  }

  &__admin {
    @include mainFrameWithTitle();
  }

  &__filters {
    column-gap: 10px;
    margin-top: 10px;
  }

  &__data {
    height: calc(100% - 45px);
    column-gap: 40px;
    &--block {
      height: calc(100% - 45px);
      column-gap: 20px;
      margin-top: 10px;
      &--header {
        height: 30px;
        background-color: var(--disabled-color);
        color: var(--level-6);
        font-size: 16px;
        font-family: fonts.$eduate-font-family;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &--left {
        height: 100%;
      }
      &--mid {
        height: 100%;
        border: 1px solid var(--border-color);
        border-radius: 6px;
        padding: 5px;
      }
      &--right {
        @extend .attendance-overview__data--block--mid;
      }
      &--body {
        height: calc(100% - 120px);
        overflow: auto;
      }
      &--buttons {
        display: flex;
        flex-direction: column;
      }
    }
    .nodata {
      margin-top: 15px;
    }

    &--admin {
      margin: 0px 20px;
      height: calc(100% - 250px);
      column-gap: 40px;

      .nodata {
        margin-top: 15px;
      }
    }
    // &--selected-session {
    //   margin: 0px 20px;
    //   height: calc(100% - 200px);
    //   column-gap: 40px;

    //   .nodata {
    //     margin-top: 15px;
    //   }
    // }
  }

  &__blocks {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    &--cards {
      @include blocksInDashboard();
      height: 80px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background-color: var(--disabled-color);
      margin: 5px 5px 5px 0px;
      img {
        width: 25px;
      }
      span {
        font-size: 13px;
        font-family: fonts.$eduate-font-family;
        color: colors.get-color(colors.$cool-grey, level_6);
      }

      b {
        font-size: 13px;
        font-family: fonts.$eduate-number-font;
      }

      &--present {
        color: colors.get-color(colors.$Green, level_4);
      }

      &--absent {
        color: colors.get-color(colors.$red, level_5);
      }

      &--sessions {
        color: colors.get-color(colors.$amber, level_5);
      }
    }
  }

  &__tableblock {
    height: 100%;
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 86%);
      font-family: fonts.$eduate-number-font;
    }

    &--admno {
      width: calc(100% - 84%);
      font-family: fonts.$eduate-number-font;
    }

    &--regno {
      width: calc(100% - 84%);
      font-family: fonts.$eduate-number-font;
    }

    &--status {
      width: calc(100% - 90%);
    }

    tr:hover {
      .attendance-overview__table--name {
        @include tdContainsOnclick();
      }
    }
  }

  &__monthly-overview {
    height: 100%;

    ul {
      margin-bottom: 0px !important;
    }

    &--title {
      display: flex;
      justify-content: space-between;
    }

    &--date {
      display: flex;
      justify-content: space-between;
      height: 35px;

      .storybook-button {
        margin-top: 0px !important;
      }
    }

    &--calendar {
      height: calc(100% - 250px);
      margin-top: 10px;
    }

    &--month {
      display: flex;
      justify-content: center;
      column-gap: 10px;
      margin-top: 10px;
      align-items: center;

      img {
        width: 20px;
      }
    }

    &--status {
      color: colors.get-color(colors.$Green, level_8);
      font-weight: 700;
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
    }

    .customized-calendar--legends {
      display: grid !important;
      grid-template-columns: 1fr 1fr 1fr !important;
      gap: 5px;
    }

    &--grid-container {
      height: calc(100% - 30px);
      width: 100%;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: auto auto auto auto auto auto auto;
      /* Rows will adjust to fit content */
      gap: 5px;
      overflow: auto;

      &--day {
        text-align: center;
        font-family: fonts.$eduate-font-family;
        font-size: fonts.$eduate-default-font-size;
        height: 100% !important;
      }
    }

    &--grid-day {
      height: 30px !important;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: auto auto auto auto auto auto auto;
      /* Rows will adjust to fit content */
      gap: 5px;
    }

    &--grid-item {
      border-radius: 6px;
      border: 1px solid var(--border-color);
      height: auto;

      &:hover {
        background-color: var(--level-2);

        .enquiry-dashboard__data--calendar--grid-item--number {
          background-color: var(--level-6);
          color: colors.get-color(colors.$colors, white);
        }
      }

      .font-green,
      .font-red {
        font-weight: 700 !important;
      }

      &--date {
        font-family: fonts.$eduate-number-font;
        font-size: 12px;
        padding-left: 5px;
      }

      &--number {
        font-family: fonts.$eduate-number-font;
        font-size: 17px;
        display: flex !important;
        justify-content: flex-end !important;
        margin-left: auto !important;
        margin-right: 0px !important;
        width: fit-content;
        border-radius: 20px 0px 0px 0px;
        padding: 2px 8px;
        background-color: colors.get-color(colors.$warm_Grey, level_2);
      }
    }

    &--number {
      font-family: fonts.$eduate-number-font;
      font-size: 12px;
      display: flex !important;
      justify-content: flex-end !important;
      margin-left: auto !important;
      margin-right: 0px !important;
      width: fit-content;
      border-radius: 20px 0px 0px 0px;
      padding: 2px 4px;
      background-color: colors.get-color(colors.$warm_Grey, level_2);
    }
  }

  &__absenties-list {
    height: 100%;

    &--title {
      display: flex;
      justify-content: space-between;

      &--close {
        display: flex;
        justify-content: flex-end;
        column-gap: 20px;
      }
    }

    &--tableblock {
      height: calc(100% - 50px);
      margin-top: 10px;
    }

    &--table {
      @include table();

      tbody {
        td {
          color: colors.get-color(colors.$red, level_5) !important;
          font-weight: 700;
        }
      }
    }

    &--textfield {
      @include textfield();
      width: fit-content !important;
      margin: 5px 0px 0px 0px !important;
    }
  }

  &__student-attendance {
    height: 100%;

    ul {
      margin-bottom: 0px !important;
    }

    .customized-calendar--legends {
      display: grid !important;
      grid-template-columns: 1fr 1fr 1fr !important;
      gap: 5px;
      margin-top: 5px;
    }

    &--details {
      height: 100px;
      border: 1.8px dashed var(--border-color);
      display: flex;
      column-gap: 10px;
      padding: 5px;

      &--image {
        @include studentProfile();

        img {
          width: 100px;
          height: calc(100px - 10px) !important;
        }
      }

      &--list {
        display: grid;
        grid-template-columns: 0.6fr 0.2fr 1fr;
        width: calc(100% - 100px);

        Label {
          margin-top: 0px !important;
        }
      }
    }

    &--percentage {
      display: flex;
      justify-content: space-between;
      height: 100px;

      b {
        margin-top: 10px;
      }
    }

    &--update-att {
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      border-bottom: 1.6px dashed var(--border-color);
      padding-bottom: 10px;
    }

    &--graph {
      height: 100%;
      width: 80px;

      .echarts-for-react {
        height: 100% !important;
      }
    }

    &--calendar {
      margin-top: 5px;
      height: calc(100% - 465px);
    }

    &--title {
      display: flex;
      justify-content: space-between;
    }

    &--grid-container {
      @extend .attendance-overview__monthly-overview--grid-container;

      &--day {
        text-align: center;
        font-family: fonts.$eduate-font-family;
        font-size: fonts.$eduate-default-font-size;
        height: 100% !important;
      }
    }

    &--grid-day {
      @extend .attendance-overview__monthly-overview--grid-day;
    }

    &--grid-item {
      border-radius: 6px;
      border: 1px solid var(--border-color);
      height: auto;

      &:hover {
        background-color: var(--level-2);
      }

      .font-green,
      .font-red {
        font-weight: 700 !important;
      }

      &--date {
        font-family: fonts.$eduate-number-font;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }

    &--month {
      @extend .attendance-overview__monthly-overview--month;
    }
  }

  &__session-list {
    height: calc(100% - 50px);
    margin-top: 10px;
    overflow: auto;
    &--session {
      @extend .attendance-overview__session-list;
      height: calc(100% - 70px);
    }

    &--li {
      background-color: colors.get-color(colors.$cool_grey, level_2);
      border-radius: 15px;
      padding: 10px;
      margin-top: 10px;
    }

    &--title {
      display: flex;
      justify-content: space-between;
      color: var(--level-5);
      font-weight: 700;
      font-family: fonts.$eduate-font-family;
      font-size: 16px;
    }

    &--flex {
      display: flex;
      column-gap: 5px;
    }

    &--textfield {
      @include textfield();
      margin-top: 10px !important;
      width: fit-content !important;
    }
  }
  &__sessions {
    display: flex;
    column-gap: 10px;
    margin-left: 10px;
    width: calc(100% - 40px);
    overflow: hidden;
  }
  &__std-att {
    height: 100%;
    &--title {
      img {
        @include closeIconInModal();
      }
    }
    &--datablock {
      height: calc(100% - 75px);
      column-gap: 20px;
      margin-top: 5px;
    }
    &--details {
      height: 100px;
      border-bottom: 1.8px dashed var(--border-color);
      display: flex;
      column-gap: 10px;
      padding: 5px;

      &--image {
        @include studentProfile();

        img {
          width: 100px;
          height: calc(100px - 10px) !important;
        }
      }

      &--list {
        display: grid;
        grid-template-columns: 0.6fr 0.2fr 1fr;
        width: calc(100% - 100px);

        Label {
          margin-top: 0px !important;
        }
      }
    }
    &--frame {
      padding: 5px;
      border: 1px solid var(--border-color);
      border-radius: 6px;
      height: 100%;
    }
    &--cards {
      height: 80px;
      gap: 10px;
      margin-top: 10px;
    }

    &--blocks {
      @include blocksInDashboard();
      border-radius: 6px;

      &--attendance {
        display: flex;
        justify-content: center !important;
        font-family: fonts.$eduate-number-font;
        font-size: fonts.$eduate-default-font-size;
        color: colors.get-color(colors.$fuchsia, level_6);
        font-weight: 700;
      }

      &--header {
        display: block;
        text-align: center;
        color: colors.get-color(colors.$grey, level_5);
        font-family: fonts.$eduate-font-family;
        font-size: fonts.$eduate-default-font-size;
        font-weight: 700;
        height: 50px !important;
      }

      &--present {
        display: flex;
        justify-content: center !important;
        font-family: fonts.$eduate-number-font;
        font-size: fonts.$eduate-default-font-size;
        font-weight: 700;
        color: colors.get-color(colors.$Green, level_6) !important;
      }

      &--absent {
        @extend .attendance-overview__std-att--blocks--present;
        color: colors.get-color(colors.$red, level_6) !important;
      }

      &--percentage {
        @extend .attendance-overview__std-att--blocks--present;
        color: colors.get-color(colors.$grey, level_6) !important;
      }
    }
    &--button {
      margin-top: 50px;
      display: flex;
      justify-content: center;
    }
    &--percentage {
      height: calc(100% - 305px);
      margin-top: 20px;
      &--text {
        b {
          font-family: fonts.$eduate-font-family;
          font-size: 16px;
          text-align: center;
          display: block;
          margin: 10px auto;
        }
      }
      &--graph {
        height: calc(100% - 50px);
        .echarts-for-react {
          height: 100% !important;
        }
      }
    }
    &--leave-details {
      display: block;
      @extend .attendance-overview__std-att--details;
      background-color: var(--disabled-color);
      &--status {
        display: flex;
        justify-content: space-between;
      }
      &--text {
        span {
          display: block;
          font-family: fonts.$eduate-font-family;
          font-size: 14px;
          color: colors.get-color(colors.$colors, cool_grey);
        }
      }
      &--pr {
        background-color: colors.get-color(colors.$Green, level_7);
        color: colors.get-color(colors.$colors, white);
        font-size: 12px;
        border-radius: 2px;
        padding: 2px;
      }
      &--edit {
        height: 15px;
        width: 15px;
      }
      &--ab {
        border: 1px solid colors.get-color(colors.$red, level_5);
        color: colors.get-color(colors.$red, level_5);
        font-size: 12px;
        border-radius: 2px;
        padding: 2px;
      }
      &--comments {
        color: colors.get-color(colors.$cool_grey, level_5);
        border-radius: 2px;
        padding: 2px;
        background-color: colors.get-color(colors.$cool_grey, level_3);
        font-size: 14px;
        text-transform: capitalize;
      }
      label {
        margin-top: 0px !important;
        padding: 0px !important;
      }
    }
    &--month {
      display: flex;
      justify-content: center;
      column-gap: 10px;
      margin-top: 10px;

      img {
        width: 20px;
      }
    }
    &--calendar {
      margin-top: 5px;
      height: calc(100% - 200px);
    }
    &--legends {
      height: 100px;
      width: fit-content !important;
      justify-content: center;
    }
    &--title {
      img {
        @include closeIconInModal();
      }
    }
    &--block {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 75px);
      flex-direction: column;
      .MuiSwitch-root {
        margin-bottom: 20px !important;
      }
    }
    &--graph {
      height: calc(100% - 70px);
      .echarts-for-react {
        height: 100% !important;
      }
    }
  }
  &__date {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
  &__b-list {
    height: 100%;
    &--header {
      @extend .attendance-overview__data--block--header;
      color: var(--text-color);
      display: flex;
      justify-content: space-between;
      align-items: center;
      b {
        font-size: 16px;
        background-color: var(--level-1);
        color: var(--level-6);
        border-radius: 6px;
        border: 1px solid var(--border-color);
        padding: 4px 10px;
        height: 30px;
        width: 30px;
      }
    }
    &--ul {
      height: calc(100% - 75px);
      margin-bottom: 0px;
      overflow: auto;
      li {
        display: flex;
        justify-content: space-between;
        padding: 3px;
        background-color: var(--disabled-color);
        border-bottom: 1px solid var(--border-color);
        margin-top: 2px;
        border-radius: 6px;
      }
      &--flex {
        display: flex;
        column-gap: 5px;
      }
      &--name {
        display: flex;
        flex-direction: column;
        gap: 2px;
        span {
          font-size: 14px;
          font-family: fonts.$eduate-font-family;
        }
        b {
          color: var(--level-6);
          font-size: 14px;
          font-family: fonts.$eduate-number-font;
        }
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
  &__sessionwise-dailyactivities {
    height: calc(100% - 90px);
    margin: 0px;
    column-gap: 40px;
  }
  &__sessionwise {
    height: calc(100% - 210px);
    margin: 0px 20px;
    column-gap: 40px;
    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--level-6);
      height: 30px;
      padding: 0px 5px;
      border-radius: 3px;
      span {
        font-size: 16px;
        font-family: fonts.$eduate-font-family;
        color: white;
        font-weight: 700;
      }
      b {
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        border-radius: 3px;
        align-items: center;
        background-color: white;
        color: var(--level-6);
      }
    }
  }
}

.attendance-overview__monthly-overview--grid-container::-webkit-scrollbar,
.attendance-overview__session-list::-webkit-scrollbar,
.attendance-overview__b-list--ul::-webkit-scrollbar,
.attendance-overview__data--block--body::-webkit-scrollbar {
  @include scrollbar();
}

.attendance-overview__monthly-overview--grid-container::-webkit-scrollbar-thumb,
.attendance-overview__session-list::-webkit-scrollbar-thumb,
.attendance-overview__b-list--ul::-webkit-scrollbar-thumb,
.attendance-overview__data--block--body::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.attendance-overview__monthly-overview--grid-container::-webkit-scrollbar-thumb:hover,
.attendance-overview__session-list::-webkit-scrollbar-thumb:hover,
.attendance-overview__b-list--ul::-webkit-scrollbar-thumb:hover,
.attendance-overview__data--block--body::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

@use "../../../scss/common/mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;

.fds-print {
  height: 100%;
  font-family: fonts.$eduate-font-family;
  &__title {
    img {
      @include closeIconInModal();
      margin-right: 5px;
    }
  }
  &__table {
    empty-cells: show !important;
    height: calc(100% - 30px) !important;

    th {
      padding: 0px;
      text-align: center;
      border-top: 1px solid var(--text-color) !important;
      border-bottom: 1px solid var(--text-color) !important;
      border-right: 1px solid var(--text-color) !important;

      font-family: fonts.$table-font-family;
      font-weight: 700;
      td {
        height: 30px !important;
      }
    }

    &--slno {
      border-left: 1px solid var(--text-color) !important;
    }
    td {
      padding: 0px;
      font-style: normal;
      font-size: 16px !important;
      font-family: fonts.$table-font-family;
      border-bottom: 1px solid var(--text-color) !important;
      border-right: 1px solid var(--text-color) !important;
      padding-left: 3px;
      padding-right: 3px;
      color: var(--text-color);
      height: 30px !important;
    }
    tfoot {
      td {
        font-weight: 700;
      }
    }
  }
  &__tableblock {
    margin-top: 10px;
  }
  &__r-details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding-top: 10px;
    b {
      font-size: 16px;
      font-family: fonts.$eduate-font-family;
      text-align: center;
    }
    &--flex {
      display: flex;
      column-gap: 5px;
      label {
        font-size: 14px;
        font-family: fonts.$eduate-font-family;
        font-weight: 700;
      }
      span {
        font-size: 14px;
        font-family: fonts.$eduate-font-family;
      }
    }
  }
  &__footer {
    font-size: 14px;
    font-family: fonts.$eduate-font-family;
    margin-top: 10px;
  }
  &__content {
    margin-top: 20px;
    .ql-editor {
      height: fit-content !important;
    }
  }
  &__inst-details {
    display: flex;
    column-gap: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dashed var(--text-color);
    &--logo {
      height: 120px;
      width: 120px;

      img {
        height: 120px;
        width: 120px;
      }
    }
    &--text {
      display: block;
      margin: auto;

      b {
        display: block;
        text-align: center;
        font-size: 22px;
        text-transform: uppercase;
      }
    }
    &--name {
      color: colors.get-color(colors.$sky, level-7);
      font-size: 18px;
      display: flex;
      justify-content: center;
    }
    &--address {
      font-family: fonts.$eduate-font-family;
      font-size: fonts.$eduate-default-font-size;
      display: flex;
      justify-content: center;
    }
  }
  &__std-details {
    margin-top: 10px;
    &--flex {
      display: flex;
      justify-content: space-between;
    }
    &--grid {
      display: grid;
      grid-template-columns: 1fr 0.1fr 1.6fr;
    }
  }
  &__block {
    height: calc(100% - 45px);
    overflow: auto;
  }
}



.fds-print__block::-webkit-scrollbar {
  @include scrollbar();
}


.fds-print__block::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.fds-print__block::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
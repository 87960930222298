@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.create-lesson-planner {
  height: 100%;
  padding: 0px 5px 5px 5px;

  &__review-comment {
    text-align: center;
    color: colors.get-color(colors.$Green, level_7);
    font-family: fonts.$eduate-font-family;
    font-size: fonts.$eduate-default-font-size;
  }

  &__header {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--buttons {
      display: flex;
      .storybook-button {
        margin: 0px 10px 0px 0px !important;
      }
    }

    &--graph {
      height: 100%;
      width: 100px;

      .echarts-for-react {
        height: 100% !important;
      }
    }

    &--durations {
      display: flex;
      column-gap: 10px;

      span {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
      }

      b {
        font-size: 16px;
        font-family: fonts.$eduate-number-font;
      }
    }

    &--separator {
      height: 20px;
      width: 2px;
      background-color: var(--border-color);
    }

    &--selected {
      background-color: var(--level-5);
      border-radius: 6px;
      padding: 3px 10px;
      font-family: fonts.$eduate-font-family;

      span,
      b {
        display: block;
        text-align: center;
        color: colors.get-color(colors.$colors, white);
      }
    }
  }

  &__topics-review{
    height: calc(100% - 125px);
    overflow: auto;
  }

  &__topics {
    height: calc(100% - 105px);
    overflow: auto;

    &--first-row {
      height: 50px;
      display: flex;
      column-gap: 10px;
    }

    .storybook-button {
      margin: 0px 10px 0px 0px !important;
      height: 35px;
    }

    &--selected-file {
      background: colors.get-color(colors.$cool_grey, level_2);

      width: 250px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 35px;
      padding: 5px;

      span {
        text-overflow: ellipsis;
        width: calc(100% - 25px);
        color: colors.get-color(colors.$colors, black);
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        white-space: nowrap;
        overflow: hidden;
        font-weight: 600;
      }

      img {
        width: 25px;
      }
    }

    &--preview {
      height: calc(100% - 60px);
      margin-top: 10px;
      overflow: auto;

      &--subtitle {
        padding-bottom: 5px;
        border-bottom: 1.5px dashed var(--border-color);
      }
    }
  }

  &__attachments {
    height: calc(100% - 75px);
    border-radius: 6px;
    border: 1px solid var(--border-color);
    position: relative;
    overflow: hidden;

    &--block {
      height: calc(100% - 30px);
      padding: 5px;
      list-style: none;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      &--image {
        margin: auto;
        height: 100px;
        width: 200px;
        display: block;
        position: relative;

        img {
          width: 100px;
          height: 100px;
          display: block;
          margin: auto;
        }

        span {
          color: colors.get-color(colors.$cool_grey, level_6);
          font-size: fonts.$eduate-default-font-size;
          font-family: fonts.$eduate-font-family;
          text-align: center;
          display: block;
        }

        input {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
        }
      }
    }

    &--footer {
      height: 30px;
      background-color: colors.get-color(colors.$cool_grey, level_2);
      color: colors.get-color(colors.$colors, black);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-family: fonts.$eduate-font-family;
      font-weight: 500;
    }
  }
}

.create-lesson-planner__topics--preview::-webkit-scrollbar,
.create-lesson-planner__topics--preview::-webkit-scrollbar,
.create-main-chapter--frame::-webkit-scrollbar {
  @include scrollbar();
}

.create-lesson-planner__topics--preview::-webkit-scrollbar-thumb,
.create-lesson-planner__topics--preview::-webkit-scrollbar-thumb,
.create-main-chapter--frame::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.create-lesson-planner__topics--preview::-webkit-scrollbar-thumb:hover,
.create-lesson-planner__topics--preview::-webkit-scrollbar-thumb:hover,
.create-main-chapter--frame::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

.create-main-chapter {
  height: 100%;

  &__title {
    display: flex;

    img {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 5px;
      right: 0px;

      &:hover {
        background-color: colors.get-color(colors.$cool_grey, level_2);
      }
    }
  }

  &--frame {
    height: calc(100% - 90px);
    margin-top: 20px;
    overflow: auto;
  }
}

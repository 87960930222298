@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.reports {
  height: 100%;

  &__info {
    height: fit-content;
    display: flex;
    justify-content: space-between;
    &--search {
      width: fit-content;
    }
    &--date {
      margin-top: 10px;
      height: 35px;
      width: fit-content;
      border: 1px solid var(--border-color);
      border-radius: 6px;
    }
  }

  &__table {
    height: calc(100% - 80px);
    margin-top: 10px;
  }

  &__modal {
    height: calc(100% - 50px);
  }
}

@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.library {
  &__frame {
    margin: 0px 10px 10px 10px;
    height: calc(100% - 185px);
    column-gap: 20px;

    &--row {
      height: calc(100% - 35px);
      margin-bottom: 10px;
      column-gap: 20px;
      display: flex;
      column-gap: 20px;
      .booktype-left,
      .booktype-right {
        width: 50% !important;
      }
    }

    &--title {
      height: 30px;

      #search {
        margin-top: 0px;
      }
    }

    &--block {
      height: calc(100% - 40px);
      overflow-y: auto;
      margin-top: 5px;

      .MuiFormControlLabel-root {
        display: flex !important;
        justify-content: space-between !important;
        padding-left: 5px;
        margin: 5px;
      }
      .MuiAutocomplete-root
        .MuiOutlinedInput-root
        .MuiAutocomplete-endAdornment {
        right: 10px !important;
      }

      .MuiChip-label {
        font-family: fonts.$eduate-font-family;
        font-size: 14px !important;
      }

      &--autocomplete {
        .MuiAutocomplete-root {
          margin-top: 10px !important;
        }

        &--multiselect {
          @include muiMultiSelect();
        }
      }

      &--keywords {
        background: colors.get-color(colors.$amber, level_1);
        border-radius: 20px;
        padding-bottom: 3px;
        width: 100%;
        margin-bottom: 3px;
        margin-top: 10px;

        &--footer {
          margin: 5px;

          label {
            background: rgba(0, 0, 0, 0.06);
            border-radius: 30px;
            padding: 4px;
            margin: 2px;
            font-size: 12px;
          }
        }

        b {
          font-size: 13px;
          margin-left: 10px;
          margin-top: 0px !important;
          color: colors.get-color(colors.$colors, black);
        }
      }
    }

    &--modal {
      height: calc(100% - 50px);
    }
  }

  &__anecdote {
    @extend .library__frame--row;
    height: calc(100% - 75px);

    &--block {
      @extend .library__frame--block;
      &--label {
        display: grid;
        grid-template-columns: 1fr 2fr;
        margin: 20px 0px;
      }
      &--quill {
        margin-top: 20px;
        border: 1px solid var(--border-color);
      }
    }
  }

  &__table {
    @include table();
    margin-top: 10px;
    height: calc(100% - 10px);

    &--slno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-slno {
      width: calc(100% - 92%);
    }

    &--status {
      width: calc(100% - 92%);
    }

    &--actions {
      width: calc(100% - 92%);
    }

    &--shortdesc {
      width: calc(100% - 93%);
    }

    &--phoneno {
      width: calc(100% - 86%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-phoneno {
      width: calc(100% - 94%);
    }

    &--email {
      width: calc(100% - 88%);
    }
  }

  &__buttons {
    margin-left: 10px;
  }
}

.library__table::-webkit-scrollbar,
.library__frame--block::-webkit-scrollbar {
  @include scrollbar();
}

.library__table::-webkit-scrollbar-thumb,
.library__frame--block::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.library__table::-webkit-scrollbar-thumb:hover,
.library__frame--block::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

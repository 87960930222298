@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.visitorqrcode {
  height: calc(100% - 45px);
  .inst-details {
    height: 100px;

    &__data {
      width: 75%;
      justify-content: space-between;

      &--logo {
        img {
          width: 80px;
          height: 80px;
        }
      }
      &--c-name {
        font-size: 14px !important;
      }
      &--address {
        span {
          font-size: 12px;
        }
        b {
          font-size: 22px;
        }
      }
    }
  }

  &__scanner {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: fonts.$eduate-font-family;
    img {
      width: 100%;
      height: 100%;
    }
    &--text {
      margin-top: 10px;
    }
  }
}

@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.student-fee-receipt {
  height: calc(100% - 210px);
  margin: 0px 20px 0px 20px;

  &--modal {
    height: calc(100% - 75px);
  }

  &__modal {
    height: calc(100% - 70px);

    &--details {
      height: 120px;
      column-gap: 20px;
    }
  }

  &__buttons {
    margin-right: 20px;
    margin-left: 20px;

    &--modal {
      margin-right: 0px;
      margin-left: 0px;
    }
  }

  &__details {
    height: 100%;

    &--select-options {
      height: 120px;
      column-gap: 20px;
    }
  }

  &__tableblock {
    margin: 0px 0px 5px 0px;
    height: calc(100% - 260px);
    column-gap: 20px;

    &--modal {
      height: calc(100% - 240px);
    }

    &--std-dashboard {
      height: calc(100% - 50px);
      margin-top: 10px;
    }
  }

  &__deposit-details {
    height: 100%;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    padding: 10px 10px;

    &--title {
      display: flex;
      justify-content: center;
      gap: 10px;
      height: 40px;
      margin-bottom: 5px;
    }
    &--totalamtblock {
      border: 1px solid var(--border-color);
      border-radius: 6px;
      height: calc(100% - 80px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: var(--level-1);
    }
    &--adjust-deposit {
      button {
        @include assignButton();
        margin-top: 5px;
        width: 100%;
      }
    }
  }

  &__receiptblock {
    height: 135px;
    column-gap: 20px;

    Label {
      margin-top: 0px;
    }

    .storybook-input {
      margin-top: 3px !important;
    }

    &--modal {
      height: 120px;
      @extend .student-fee-receipt__receiptblock;

      &--textfield {
        @include textfield();
        margin: 8px 0px 0px 0px !important;
      }
    }

    &--frame {
      border: 1px solid colors.get-color(colors.$colors, cloud-white);
      padding: 5px 10px 5px 10px;
      border-radius: 6px;
      height: 100%;

      &--modal {
        @extend .student-fee-receipt__receiptblock--frame;
      }

      &--bankdetails {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      .MuiFormControl-root {
        margin-top: 5px !important;
      }
    }

    &--edit {
      &--text {
        display: flex;
        justify-content: space-between;
      }

      &--textfield {
        @include textfield();
        margin: 10px 0px 0px 0px !important;
        width: 100%;

        &--date {
          @extend .student-fee-receipt__receiptblock--edit--textfield;
          width: fit-content !important;
        }

        input {
          font-weight: 400 !important;
        }

        label {
          color: var(--text-color) !important;
          font-size: 16px !important;
        }
      }

      &--label-grid {
        display: grid;
        grid-template-columns: 0.8fr 1fr;
      }
    }
  }

  &__frame {
    .MuiAutocomplete-root {
      margin-top: 8px !important;
    }

    .data-fetch-icon {
      margin-top: 8px;
    }

    &--textfield {
      @include textfield();
      margin: 8px 10px 0px 0px !important;

      &--date {
        @include textfield();
        width: fit-content !important;
        margin: 8px 10px 0px 0px !important;
      }
    }

    &--imagefield {
      display: flex;

      .MuiAutocomplete-root {
        width: 100% !important;
      }
    }

    &--image {
      @include studentProfile();
      height: 100%;
      img {
        height: calc(100% - 10px);
        width: calc(100% - 10px);
      }
    }
  }

  &__table {
    @include Accountstable();
    height: 100%;

    &__borderbottom {
      border-bottom: 1px solid colors.get-color(colors.$cool_grey, level_4) !important;
      position: sticky;
      position: -webkit-sticky;
    }

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--date {
      width: calc(100% - 94%);
      font-family: fonts.$eduate-number-font;
    }

    &--amount {
      width: calc(100% - 91%);
      font-family: fonts.$eduate-number-font;
      @include inputSalaryTable();
      input {
        background-color: colors.get-color(colors.$yellow, level_2) !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        width: 100% !important;
      }
    }

    &--th-slno {
      width: calc(100% - 96%);
    }
    &--th-date {
      width: calc(100% - 94%);
    }
    &--th-amount {
      width: calc(100% - 91%);
    }
    &--th-desc {
      width: calc(100% - 91%);
    }
  }

  &__total {
    display: flex;
    justify-content: flex-end;
  }

  &__totalcount {
    @include totalCountShadowEffect();
    margin-top: 5px;
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1.8fr 0.35fr;
  }

  &__label-grid {
    display: grid;
    grid-template-columns: 1fr 1.8fr;
    padding-right: 10px;
  }

  &__label-gridtrio {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 5px;

    Button {
      padding: 0px !important;
      margin: 0px;
    }
  }

  &__advance-fee-modal {
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    padding: 2px;

    Label {
      margin-top: 0px !important;
    }

    &:hover {
      background-color: var(--level-2);
    }

    &--amount {
      color: colors.get-color(colors.$Green, level_5);
      margin-top: 10px;
      font-family: fonts.$eduate-font-family;
      font-size: fonts.$eduate-default-font-size;
    }
  }

  &__waive-off {
    height: calc(100% - 115px);

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }

      &--date {
        width: calc(100% - 85%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-slno {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-date {
        width: calc(100% - 85%);
        font-family: fonts.$eduate-number-font;
      }

      &--amount {
        width: calc(100% - 82%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-amount {
        width: calc(100% - 82%);
        font-family: fonts.$eduate-number-font;
      }
    }
  }
}

#receivedamount {
  text-align: end;
  padding: 5px;
  border-radius: 6px !important;
  background: colors.get-color(colors.$amber, level_2) !important;
  margin-top: 0px;

  &::placeholder {
    text-align: left;
    font-weight: 500 !important;
    color: colors.get-color(colors.$colors, gray);
  }
}

.student-fee-receipt::-webkit-scrollbar {
  display: none;
}

.student-fee-receipt__details::-webkit-scrollbar {
  display: none;
}

.narration-grid {
  display: grid;
  grid-template-columns: 0.5fr 3.5fr;
}

.deposit td {
  background-color: var(--level-2);
}

.delete-demand-fee-receipt {
  height: calc(100% - 85px);
  margin-top: 10px;

  &__details {
    column-gap: 20px;

    #search {
      margin-top: 0px;
    }

    &--textfield {
      @include textfield();

      input {
        font-weight: 400 !important;
      }
    }
  }

  &__tableblock {
    height: calc(100% - 50px);
    margin-top: 10px;
  }

  &__table {
    @include table();

    &--th-slno {
      width: calc(100% - 90%);
    }

    &--slno {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--amount {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-amount {
      width: calc(100% - 88%);
    }

    &--vno {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-vno {
      width: calc(100% - 88%);
    }

    &--actions {
      width: calc(100% - 92%);
    }
  }
}

// for ob receipt table
.for-ob-receipt {
  height: calc(100% - 280px) !important;
}

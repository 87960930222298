@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.visitor-dashboard {
  height: calc(100% - 165px);
  margin: 0px 20px 10px 20px;
  &__menu {
    &--tabs {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      @include muiTabs();
    }
  }
  &__tabpanel {
    height: calc(100% - 75px);
    .MuiTypography-root {
      height: 100% !important;
    }
  }

  &__details {
    column-gap: 20px;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    &--block {
      padding: 5px;
      margin-top: 10px;
      @include blocksInDashboard();
      height: fit-content;

      &--title {
        display: flex;
        justify-content: space-between;
        padding-left: 10px;

        &--tabs {
          @include muiTabsInDashboard();
        }

        .subtitle1 {
          width: calc(100% - 35px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          text-align: left;

          &:hover {
            text-overflow: clip;
            overflow: visible;
          }
        }
      }

      &--details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 30px;
        height: calc(100% - 40px);

        img {
          width: 60px;
          height: 60px;
        }

        &--total {
          display: block;
          text-align: center;
          margin: auto;
          font-family: fonts.$eduate-number-font;
          font-weight: bold;
          font-size: 18px;
          color: colors.get-color(colors.$grey, level_6);
        }
      }
    }

    &--qrcode-block {
      @include blocksInDashboard();
      height: fit-content;
      margin-top: 10px;

      &:hover {
        background-color: var(--level-1);
      }

      &--qrcode {
        display: flex;
        align-items: center;
        height: 60%;
        margin: 15px;
        img {
          width: 200px;
          height: 200px;
        }
      }
    }
  }

  &__statistics {
    height: calc(100% - 120px);
    column-gap: 20px;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    &--block {
      padding: 5px;
      margin-top: 10px;
      @include blocksInDashboard();
      height: calc(100% - 20px);

      &--check-in {
        @extend .visitor-dashboard__statistics--block;
        overflow: auto;
      }

      &--title {
        display: flex;
        justify-content: space-between;
        padding-left: 10px;

        &--tabs {
          @include muiTabsInDashboard();
        }
      }

      &--datablock {
        height: calc(100% - 50px);
        column-gap: 10px;
        margin-top: 20px;
        display: flex;
        width: 100%;
        &--frame {
          display: flex;
          width: 64%;
          flex-direction: column;
        }
        &--graph {
          height: calc(100% - 20px);
        }

        &--tableblock {
          height: calc(100% - 40px);

          &--table {
            @include table();
          }
        }

        &--services {
          height: calc(100% - 50px);
          overflow: auto;

          li {
            display: flex;
            justify-content: space-between;
            column-gap: 10px;
            padding-bottom: 5px;
            border-bottom: 1px solid var(--border-color);

            .Label {
              margin-top: auto !important;
              margin-bottom: auto !important;
            }
          }
        }

        &--chart {
          height: 100% !important;
        }
      }

      &--request {
        height: calc(100% - 90px);
        margin: 0px 10px 10px 10px;
        overflow: auto;
        &--details {
          font-family: fonts.$eduate-font-family;
          align-items: center;
          border-bottom: 1px solid var(--border-color);
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          &--list {
            display: block;
          }
          &--button {
            height: 25px;
            column-gap: 10px;
          }
        }
        &--view {
          display: flex;
          justify-content: center;
          &--button {
            border: 1px solid var(--border-color);
            border-radius: 50px;
            width: fit-content;
            padding: 8px 10px 8px 10px;
          }
        }
      }
    }
  }
}

@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.feedemand-additionalfee-group {
  height: calc(100% - 210px);
  &__details {
    margin: 0px 10px 0px 10px;

    #receivedamount {
      margin-top: 5px;
      width: 60%;
    }
    Label {
      margin-top: 5px;
      padding: 0px;
    }
    .MuiFormControl-root {
      margin-top: 3px !important;
    }
  }
  &__tableblock {
    margin: 10px 20px 0px 20px;
    height: calc(100% - 170px);
    &--table {
      @include table();
      @include tableCheckbox();
      &--checkbox {
        width: calc(100% - 96%);
      }
      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }
      &--admno {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }
      &--regno {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-slno {
        width: calc(100% - 96%);
      }
      &--th-admno {
        width: calc(100% - 90%);
      }
      &--th-regno {
        width: calc(100% - 90%);
      }
      &--category {
        width: calc(100% - 92%);
      }
      &--year {
        width: calc(100% - 90%);
      }
      &--amount {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-amount {
        width: calc(100% - 88%);
      }
    }
  }
  &__total {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    &--count {
      @include totalCountShadowEffect();
      margin-top: 5px;
    }
  }
  &__view {
    &--tableblock {
      height: calc(100% - 75px);
    }
    &--table {
      @include table();
    }
  }
  &__textfield {
    @include textfield();
    width: fit-content !important;
    label {
      margin-top: 0px !important;
    }
  }
}

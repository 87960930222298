@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.late-fee-payment {
  @include mainFrameWithTitle();

  &__details {
    height: 120px;
    column-gap: 20px;

    &--image-flex {
      display: flex;
      margin-top: 8px !important;
    }

    &--textfield {
      @include textfield();
      margin: 10px 10px 0px 0px !important;

      &--balance {
        @include textfield();
        margin: 10px 10px 0px 0px !important;
      }

      &--date {
        @include textfield();
        margin: 10px 10px 0px 0px !important;
        width: fit-content !important;
      }
    }

    &--image {
      height: 100% !important;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &--label-grid {
      display: grid;
      grid-template-columns: 0.38fr 1fr;
      .storybook-textarea {
        margin-top: 10px !important;
      }
      padding: 10px;
    }
    &--upload {
      .documents-upload {
        display: grid;
        grid-template-columns: 1fr 1.5fr 0.6fr !important ;
        &--filename {
          width: 190px !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  &__tableblock {
    column-gap: 20px;
    margin-top: 20px;
    border-top: 1.8px dashed var(--border-color);
    padding-top: 10px;
    height: calc(100% - 188px);
    display: flex;
    column-gap: 20px;

    &--permission-details {
      border: 1px solid var(--border-color);
      border-radius: 10px;
      height: 100%;
      padding: 10px;
      width: 480px;
    }
    &--permission-ledger {
      @extend .late-fee-payment__tableblock--permission-details;
      .MuiDataGrid-root {
        border-style: none !important;
        height: calc(100% - 35px) !important;
        margin-top: 5px;
      }
      width: calc(55% - 240px) !important;
    }
    &--permission-letter {
      padding: 10px;
      width: calc(45% - 240px) !important;
      .MuiDataGrid-root {
        border-style: none !important;
        height: calc(100% - 35px) !important;
        margin-top: 5px;
      }
    }
  }

  &__view {
    height: calc(100% - 80px);
    margin-top: 10px;

    &--details {
      column-gap: 10px;

      #search {
        margin-top: 0px;
      }
    }

    &--table {
      @include table();
      margin-top: 10px;
      height: calc(100% - 50px);
    }
  }

  .student-documents__upload--buttons {
    margin-top: 5px;
    display: grid;
    grid-template-columns: 1fr 0.5fr 0.5fr;
    gap: 10px;

    Button,
    label {
      margin-right: 0px;
    }
  }
}

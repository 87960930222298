@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.id-card-template {
  height: calc(100% - 175px);
  margin: 5px 20px 0px 20px;

  &__flex {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    height: 100%;
  }

  &__ls {
    width: 350px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    height: 100%;

    &--tabs {
      @include muiTabs();
      padding: 5px;
    }

    &--tabpanel {
      margin-top: 10px;
      height: calc(100% - 70px);
      overflow: auto;
    }
  }

  &__md {
    width: calc(100% - 670px);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    height: 100%;
  }

  &__rs {
    width: 320px;
    height: 100%;

    select {
      border-radius: 6px;
      border: 1px solid var(--border-color) !important;
      background-color: var(--bg-color);
      width: 100%;
      height: 30px;
      margin-top: 10px;
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
      color: var(--text-color);
      padding: 8px;

      li {
        padding: 5px;

        &:hover {
          display: none;
        }
      }
    }

    &--grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .module-wise-configuration {
      height: calc(100% - 30px) !important;
      margin: 0px 0px 0px 0px !important;
    }

    &--with-colors {
      height: calc(100% - 200px);

      .module-wise-configuration {
        height: 100% !important;
      }
    }
  }
}

.id-card-template__ls--tabpanel::-webkit-scrollbar,
.id-cards-print::-webkit-scrollbar {
  @include scrollbar();
}

.id-card-template__ls--tabpanel::-webkit-scrollbar-thumb,
.id-cards-print::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.id-card-template__ls--tabpanel::-webkit-scrollbar-thumb:hover,
.id-cards-print::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

.id-cards-print {
  height: calc(100% - 75px);
  width: 100%;
  overflow: auto;
  &__title {
    img {
      @include closeIconInModal();
    }
  }
  .id-template-1__card--fs--li,
  .id-template-2__card--fs--li {
    height: 100% !important;
  }

  .id-template-1__card--fs--id,
  .id-template-2__card--fs--id {
    height:100% !important;
  }

  &--print {
    height: 400px;
    width: 250px;
  }

  .id-template-1,
  .id-template-2 {
    margin: 0px 0px !important;
    width: 100%;
    height: fit-content !important;
  }

  .h-100 {
    width: 100%;
    height: fit-content !important;
  }

  .id-template-1__card,
  .id-template-2__card {
    height: fit-content !important;
    width: 100%;
  }

  .id-template-1__card--fs--li,
  .id-template-2__card--fs--li {
    height: 400px !important;
    width: 100%;
  }

  .id-template-1__card--bs,
  .id-template-2__card--bs {
    margin-top: 0px !important;
    height: 400px;
    width: 250px;
  }
  .id-template-2__card--print {
    width: 100% !important;
    height: fit-content !important;
    margin-bottom: 10px !important;
  }
}

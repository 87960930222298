@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;

.master-data {
  height: calc(100% - 185px);
  margin: 0px 10px 10px 10px;
  // @include mainFrame();
  &--modal {
    height: calc(100% - 30px);
    &--title {
      img {
        @include closeIconInModal();
      }
    }
  }
  &__datablock {
    height: calc(100% - 35px);
    display: flex;
    column-gap: 30px;
    margin-top: 10px;
    &--modal {
      @extend .master-data__datablock;
      height: calc(100% - 45px);
      margin-top: 0px;
    }
    &--left {
      height: 100%;
      border-radius: 0px 30px 0px 0px;
      border: 1px solid var(--border-color);
      width: 50%;
      padding: 10px;
    }
    &--right {
      @extend .master-data__datablock--left;
      border-radius: 30px 0px 0px 0px;
    }
  }
  &__list {
    height: 100%;
    &--tableblock {
      height: calc(100% - 30px);
      margin-top: 10px;
      .MuiDataGrid-root {
        border-style: none !important;
      }
    }
  }
  &__add {
    height: 100%;
    &--form {
      margin-top: 10px;
    }
    &--form-labels {
      // @include formControlLabel();
    }
  }
}
